// @flow

export default {
  'contactForm.errors.email': 'Must be a valid email address',
  'contactForm.errors.required': 'Required',
  'contactForm.errors.mustAcceptPrivacyPolicy': 'Must accept privacy policy',
  'contactForm.labels.name': 'Name',
  'contactForm.labels.body': 'Description',
  'contactForm.labels.email': 'Email address',
  'contactForm.labels.phone': 'Phone',
  'contactForm.labels.privacyPolicy': 'Privacy Policy',
  'contactForm.labels.acceptPrivacyPolicy':
    'I accept {link, select, undefined {política de privacidad} other {{link}}}',
  'contactForm.labels.subject': 'Subject',
  'contactForm.labels.submit': 'Submit',
  'contactForm.onError': 'Could not send submission.',
  'contactForm.onReject': 'Could not send submission.',
  'contactForm.onSuccess': 'Submission sent!',
  'prismic.preview.nodePageInfo': `
    <p>
      <strong><code>{path}</code></strong> preview page
    </p>
    <p>Preview page considerations:</p>
    <ul>
      {translationsCount, plural, =0 {} other {
      <li>translations will not load</li>
      } }
      <li>the preview data will be lost when changing pages</li>
    </ul>
  `,
  'newsletterForm.errors.email': 'Must be a valid email address',
  'newsletterForm.errors.required': 'Required',
  'newsletterForm.labels.email': 'Email address',
  'newsletterForm.labels.submit': 'Submit',
  'reseller.labels.types': 'RESELLER TYPE',
  'reseller.labels.resellerLocation': 'See location',
  'reseller.labels.resellerLocations': 'Other {reseller} locations',
  'filter.labels.results': 'results',
  'filter.labels.postcode': 'ZIP/Postal code',
  'filter.labels.enterPostcode': 'Please enter a valid ZIP/Postal code',
  'global.labels.allCountriesKey': 'All countries',
};
