// @flow
import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Helmet from 'react-helmet';
import { useIntl } from 'react-intl';
import toString from 'lodash/toString';
import SEO from './SEO';

import usePageContext from '~plugins/page-context/hooks/usePageContext';

import type { Query } from '~schema';

export type Props = {
  schemaKey?: ?string,
  title?: string,
  description?: string,
  siteName?: string,
  image?: {
    url?: ?string, // Need to be conditionals if we want the other default to work.
    alt?: ?string,
    width?: ?string,
    height?: ?string,
  },
  keywords?: Array<string>,
  children?: React.Node,
  author?: {
    name?: ?string,
    email?: ?string,
  },
  alternateName?: string,
  datePublished?: ?string,
  dateModified?: ?string,
};

const query = graphql`
  query AppHelmetQuery {
    site {
      siteMetadata {
        siteName
        siteUrl
        title
        description
        author {
          name
          email
        }
        image {
          url
          alt
          width
          height
        }
        organization {
          name
          url
          email
          logo {
            url
            alt
            width
            height
          }
        }
        datePublished
        dateModified
        social {
          twitter
          fbAppID
        }
      }
    }
    prismicOrganization {
      data {
        meta_name {
          text
        }
        meta_email {
          text
        }
        meta_url {
          text
        }
        meta_logo {
          url
          alt
          dimensions {
            width
            height
          }
        }
      }
    }
  }
`;

const AppHelmet = ({
  title,
  description,
  siteName,
  keywords,
  image,
  author,
  alternateName,
  children,
  datePublished,
  dateModified,
  ...props
}: Props) => {
  const intl = useIntl();
  const data = useStaticQuery<Query>(query);
  const pageContext = usePageContext();

  const metaData = data.site?.siteMetadata || {};
  const organization = data.prismicOrganization?.data;
  // const title$short = title || metaData?.title || '';
  const title$long = `${title ? `${title} | ` : ''}${metaData?.title || ''}`;
  const pagePath = pageContext.localizedPath || '';
  const siteUrl = metaData?.siteUrl || '';
  const pageUrl = `${siteUrl}${pagePath}`;
  return (
    <>
      <SEO
        lang={intl.locale}
        locale={pageContext.locale.tagIETF}
        title={title$long}
        description={description || metaData.description || ''}
        keywords={keywords}
        url={pageUrl}
        pageType={pageContext.pageType}
        siteName={siteName || metaData.siteName || ''}
        image={{
          url: image?.url || metaData.image?.url || '',
          alt: image?.alt || metaData.image?.alt || '',
          width: image?.width || metaData.image?.width || '',
          height: image?.height || metaData.image?.height || '',
        }}
        alternateName={alternateName || metaData.title || ''}
        siteUrl={metaData.siteUrl || ''}
        author={{
          name: author?.name || metaData.author?.name || '',
          email: author?.email || metaData.author?.email || '',
        }}
        organization={{
          url: organization?.meta_url?.text || metaData.organization?.url || '',
          name:
            organization?.meta_name?.text || metaData.organization?.name || '',
          email:
            organization?.meta_email?.text ||
            metaData.organization?.email ||
            '',
          logo: {
            url:
              organization?.meta_logo?.url ||
              metaData.organization?.logo?.url ||
              '',
            alt:
              organization?.meta_logo?.alt ||
              metaData.organization?.logo?.alt ||
              '',
            width:
              toString(organization?.meta_logo?.dimensions?.width) ||
              metaData.organization?.logo?.width ||
              '',
            height:
              toString(organization?.meta_logo?.dimensions?.height) ||
              metaData.organization?.logo?.height ||
              '',
          },
        }}
        datePublished={datePublished || metaData.datePublished || ''}
        dateModified={dateModified || metaData.dateModified || ''}
        social={{
          twitter: metaData.social?.twitter,
          fbAppID: metaData.social?.fbAppID,
        }}
        {...props}
      />
      {children ? <Helmet>{children}</Helmet> : null}
    </>
  );
};

AppHelmet.defaultProps = {
  schemaKey: undefined,
  title: undefined,
  description: undefined,
  siteName: undefined,
  keywords: [],
  children: null,
  author: undefined,
  alternateName: undefined,
  image: undefined,
  datePublished: undefined,
  dateModified: undefined,
};

export default AppHelmet;
