// @flow
/* eslint-disable react/require-default-props */
import * as React from 'react';
import withStyles, {
  type StyleRulesCallback,
  type WithStyles,
} from '@plugins/material-ui/hocs/withStyles';
import type { Theme } from '@material-ui/core/styles/createMuiTheme';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import classnames from 'classnames';
import map from 'lodash/map';

import FieldLink from '~plugins/prismic/components/FieldLink';
import type { PrismicMenu } from '~schema';

export type ClassKey = 'root' | 'item';

export type Props = {
  ...$Exact<WithStyles<ClassKey>>,
  className?: string,
  open?: boolean,
  handleClose?: () => void,
  menuLinks: ?PrismicMenu,
};

export type Styles = StyleRulesCallback<Theme, Props, ClassKey>;

export const styles: Styles = theme => ({
  root: {
    width: '100%',
  },
  item: {
    ...theme.typography.button,
  },
});

const DrawerItemMenuLinks = React.forwardRef<Props, *>(
  function DrawerItemMenuLinksInner(
    {
      open,
      handleClose,
      menuLinks,
      className,
      classes,
      children,
      ...props
    }: Props,
    ref,
  ): React.Node {
    return (
      <MenuList
        ref={ref}
        open={open}
        id="menu-list"
        className={classnames(classes.root, className)}
        {...props}
      >
        {map(menuLinks?.data?.menu_actions, (action, index: number) => (
          <MenuItem
            key={index}
            component={FieldLink}
            field={action?.action_link}
            onClick={handleClose}
            aria-label={action?.action_label?.text}
            className={classes.item}
          >
            {action?.action_label?.text}
          </MenuItem>
        ))}
      </MenuList>
    );
  },
);

export default withStyles<*, *, Props>(styles, { name: 'DrawerItemMenuLinks' })(DrawerItemMenuLinks);