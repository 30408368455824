// @flow
import * as React from 'react';
import withStyles from '@plugins/material-ui/hocs/withStyles';
import classNames from 'classnames';

import type { Props } from './types';
import styles from './styles';

const ResPadding = ({
  classes,
  children,
  vertical,
  horizontal,
  negative,
  className,
  ...props
}: Props) => (
  <div
    className={classNames(
      classes.root,
      {
        [classes.horizontal]: horizontal,
        [classes.vertical]: vertical,
      },
      className,
    )}
    {...props}
  >
    {children}
  </div>
);

ResPadding.defaultProps = {
  vertical: false,
  horizontal: false,
  className: '',
  negative: false,
};

export default withStyles<*, *, Props>(styles, { name: 'ResPadding' })(ResPadding);
