// @flow
import * as React from 'react';
import type { Breakpoint } from '@material-ui/core/styles/createBreakpoints';

import type { BreakpointFactors } from '../types';

// do not change these defaults, as they match the padding set by
// Container component
const defaultFactors: BreakpointFactors = {
  xs: 2,
  sm: 3,
  md: 4,
  lg: 4,
  xl: 4,
};

const getBreakPointFactor = (
  breakpoint: Breakpoint,
  factors?: BreakpointFactors,
): number =>
  ({
    ...defaultFactors,
    ...factors,
  }[breakpoint]);

export default getBreakPointFactor;
