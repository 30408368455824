// @flow
/* eslint-disable react/require-default-props */
/* eslint-disable no-use-before-define */
import * as React from 'react';
import withStyles, {
  type StyleRulesCallback,
  type WithStyles,
} from '@plugins/material-ui/hocs/withStyles';
import type { Theme } from '@material-ui/core/styles/createMuiTheme';
import Grid, { type GridSize } from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import classnames from 'classnames';
import map from 'lodash/map';

import FieldLink from '~plugins/prismic/components/FieldLink';
import type { PrismicMenu } from '~schema';

export type ClassKey = 'root' | 'item';

export type Props = {
  ...$Exact<WithStyles<ClassKey>>,
  className?: string,
  open?: boolean,
  handleClose?: () => void,
  menuLinks: ?PrismicMenu,
  maxLinksPerColumn?: number,
  gridItemProps: { md: GridSize },
};

export type Styles = StyleRulesCallback<Theme, Props, ClassKey>;

export const styles: Styles = unusedTheme => ({
  root: {},
  item: {},
});

const AppBarMenuLinks = React.forwardRef<Props, *>(
  function AppBarMenuLinksInner(
    {
      open,
      handleClose,
      menuLinks,
      maxLinksPerColumn,
      gridItemProps,
      className,
      classes,
      children,
      ...props
    }: Props,
    ref,
  ): React.Node {
    return (
      <Grid
        container
        ref={ref}
        open={open}
        className={classnames(classes.root, className)}
        {...props}
      >
        {map(menuLinks?.data?.menu_actions, (action, index: number) => (
          <Grid item key={index} className={classes.item} {...gridItemProps}>
            <Button
              component={FieldLink}
              field={action?.action_link}
              onClick={handleClose}
              aria-label={action?.action_label?.text}
              className={classes.item}
            >
              {action?.action_label?.text}
            </Button>
          </Grid>
        ))}
      </Grid>
    );
  },
);

export default withStyles<*, *, Props>(styles, { name: 'AppBarMenuLinks' })(AppBarMenuLinks);
