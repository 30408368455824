/* eslint-disable no-use-before-define */
const React = require('react');

const IntlPageRedirect = require('./src/components/IntlPageRedirect');
const createMemoryStorage = require('./src/helpers/createMemoryStorage');
const createCookieStorage = require('./src/helpers/createCookieStorage');

const memoryStorage = createMemoryStorage();
const cookieStorage = createCookieStorage();

exports.wrapPageElement = function intlPageRedirectWrapPageElement(
  { element, props: { pageContext } },
  pluginOptions,
) {
  return (
    <>
      <IntlPageRedirect
        pageContext={pageContext}
        storage={
          pluginOptions.storage === 'cookie' ? cookieStorage : memoryStorage
        }
        pluginOptions={pluginOptions}
      />
      {element}
    </>
  );
};
