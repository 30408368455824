// @flow
import { graphql, useStaticQuery } from 'gatsby';

import useFindCurrentLocaleNode from '../../prismic/hooks/useFindCurrentLocaleNode';
import type { Query, PrismicCookieConsent } from '~schema';

const query = graphql`
  query UsePrismicCookieConsentDefaultQuery {
    allPrismicCookieConsent {
      nodes {
        id
        prismicId
        type
        lang
        data {
          consent_type
          consent_title {
            text
          }
          consent_description {
            text
            html
          }
          consent_button_read_more {
            text
          }
          consent_button_accept {
            text
          }
          read_more_link {
            link_type
            url
            target
          }
        }
      }
    }
  }
`;

export default function usePrismicCookies(): ?PrismicCookieConsent {
  return useFindCurrentLocaleNode<PrismicCookieConsent>({
    nodes: useStaticQuery<Query>(query).allPrismicCookieConsent?.nodes,
    toQuery: prismicCookieConsent => ({ prismicCookieConsent }),
    fromQuery: data => data?.prismicCookieConsent,
  });
}
