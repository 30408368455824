// @flow
import { graphql, useStaticQuery } from 'gatsby';

import useFindCurrentLocaleNode from '~plugins/prismic/hooks/useFindCurrentLocaleNode';
import type { Query, PrismicNewsletterForm } from '~schema';

const query = graphql`
  query UseNewsletterFormDefaultQuery {
    allPrismicNewsletterForm {
      nodes {
        lang
        id
        data {
          name {
            text
            html
          }
          title {
            text
            html
          }
          description {
            text
            html
          }
          zoho_listkey
          label_email {
            text
            html
          }
          label_accept_privacy_policy {
            text
            html
          }
          label_submit {
            text
            html
          }
          error_email {
            text
            html
          }
          error_must_accept_privacy_policy {
            text
            html
          }
          error_required {
            text
            html
          }
          event_error {
            text
            html
          }
          event_reject {
            text
            html
          }
          event_success {
            text
            html
          }
          event_subscribed {
            text
            html
          }
        }
      }
    }
  }
`;

export default function useNewsletterForm(): ?PrismicNewsletterForm {
  return useFindCurrentLocaleNode<PrismicNewsletterForm>({
    nodes: useStaticQuery<Query>(query).allPrismicNewsletterForm?.nodes,
    toQuery: prismicNewsletterForm => ({ prismicNewsletterForm }),
    fromQuery: data => data?.prismicNewsletterForm,
  });
}
