// @flow
import * as React from 'react';
import withStyles from '@plugins/material-ui/hocs/withStyles';
import CircularProgress from '@material-ui/core/CircularProgress';

import StyledNotchedButton from '~components/StyledNotchedButton';

import styles from './styles';
import type { Props, MuiButtonProps } from './types';

const progressSizes: {
  [$ElementType<MuiButtonProps, 'size'>]: number,
} = { small: 20, medium: 24, large: 28 };

const LoadingButton = ({
  loading,
  disabled,
  children,
  size,
  variant,
  spinnerColor,
  classes: {
    progressWrapper: classes$progressWrapper,
    progress: classes$progress,
    ...buttonClasses
  },
  ...props
}: Props) => (
  <StyledNotchedButton
    disabled={!!(loading || disabled)}
    size={size}
    variant={variant}
    {...props}
    classes={buttonClasses}
  >
    {children}
    {!loading ? null : (
      <div className={classes$progressWrapper}>
        <CircularProgress
          size={progressSizes[size || 'medium']}
          color={spinnerColor}
          className={classes$progress}
        />
      </div>
    )}
  </StyledNotchedButton>
);

export default withStyles<*, *, Props>(styles, { name: 'LoadingButton' })(LoadingButton);
