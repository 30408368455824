// @flow
import * as React from 'react';
import withStyles, {
  type StyleRulesCallback,
  type WithStyles,
} from '@plugins/material-ui/hocs/withStyles';
import type { Theme } from '@material-ui/core/styles/createMuiTheme';
import MuiTextField, { type TextFieldProps } from '@material-ui/core/TextField';

export type ClassKey = 'root';

export type Variant = $ElementType<
  React.ElementConfig<typeof MuiTextField>,
  'variant',
>;

export type Props = {
  // $FlowFixMe --> Reason: cannot instantiate `WithStyles` because  string literal `root` [1] is incompatible with  `StyleRules`
  ...$Exact<WithStyles<ClassKey>>,
  ...$Exact<$Diff<TextFieldProps<*, {}>, {}>>,
  className?: string,
  color: string,
  variant: Variant,
  TextField?: React.ComponentType<TextFieldProps<*, {}>>,
};

export type Styles = StyleRulesCallback<Theme, Props, ClassKey>;

export const styles: Styles = unusedTheme => ({
  root: {
    '& > div, & > div:hover, & > div.Mui-focused': {
      backgroundColor: ({ color }) => color,
    },
  },
});

const StyledTextField = ({
  color,
  variant,
  className,
  classes,
  TextField = MuiTextField,
  ...props
}: Props): React.Node => {
  return (
    <TextField
      variant={variant || 'filled'}
      InputProps={{ disableUnderline: true }}
      classes={{ root: classes.root }}
      {...props}
    />
  );
};

StyledTextField.defaultProps = {
  className: undefined,
  TextField: undefined,
};

export default withStyles<*, *, Props>(styles, { name: 'StyledTextField' })(StyledTextField);
