// @flow
import { navigate } from 'gatsby';

export default function onDocumentLinkClick(event: Event) {
  const element = event.currentTarget;
  if (element instanceof HTMLElement) {
    const href =
      element && element.attributes ? element.getAttribute('href') : null;
    if (href) {
      event.preventDefault();
      navigate(href);
    }
  }
}
