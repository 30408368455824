// @flow
import type { Locale } from '../../i18n/types';

export default function selectPath(
  localeOrId: ?string | ?Locale,
  pathsDict: { [string]: string },
  defaultPath: string,
) {
  const localeId =
    typeof localeOrId === 'string' ? localeOrId : localeOrId && localeOrId.id;
  return (localeId && pathsDict[localeId]) || defaultPath;
}
