// @flow
import * as React from 'react';
import warning from 'warning';
import { oneLine } from 'common-tags';

import Link from '../../../i18n/components/Link';
import NodeLinkInner from './NodeLinkInner';

export type Props = {
  ...$Exact<React.ElementConfig<typeof NodeLinkInner>>,
  children: React.Element<typeof NodeLinkInner>,
  linkChildren: React.Node,
};

export type State = {
  error: ?Error,
};

export default class NodeLinkErrorBoundary extends React.PureComponent<
  Props,
  State,
> {
  state = { error: null };

  static getDerivedStateFromError(error: Error) {
    return { error };
  }

  componentDidCatch() {
    const { node } = this.props;
    warning(
      false,
      oneLine`
        an error was throw while rendering NodeLink, probably because the
        provided node of type '${node && node.type}' has not enough data.
        Make sure that the query where this node is retrieved contains all
        the fields used in the corresponding path resolver.
      `,
    );
  }

  render() {
    const { children, linkChildren, node: unusedNode, ...props } = this.props;
    const { error } = this.state;
    return error ? (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <Link {...props} to="#" disabled>
        {linkChildren}
      </Link>
    ) : (
      children
    );
  }
}
