// @flow
import type { PathResolver } from '../../src/plugins/prismic/types';
import type { PathResolvers, PathResolverByType } from './types';

const { oneLine } = require('common-tags');

const contentTypes = require('./contentTypes');
const selectPath = require('../../src/plugins/prismic/helpers/selectPath')
  .default;

/* GatsbyStarterConfig: paths for Prismic nodes */
const pathResolvers: PathResolvers = {
  '404_page': () => '/404',
  blog_entry: ({ locale, doc }) =>
    selectPath(locale, {}, `/blog/${doc.uid || doc.id}`),
  news_entry: ({ locale, doc }) =>
    selectPath(locale, {}, `/${doc.uid || doc.id}`),
  news_entry_archive: ({ locale, doc }) =>
    selectPath(locale, {}, `/${doc.uid || doc.id}`),
  press_entry: ({ locale, doc }) =>
    selectPath(locale, {}, `/${doc.uid || doc.id}`),
  press_entry_archive: ({ locale, doc }) =>
    selectPath(locale, {}, `/${doc.uid || doc.id}`),
  contact_page: ({ locale, doc }) =>
    selectPath(locale, {}, `/${doc.uid || doc.id}`),
  contact_form: () => '/',
  white_paper_form: () => '/',
  home_page: () => '/',
  sector_page: ({ locale, doc }) =>
    selectPath(locale, {}, `/${doc.uid || doc.id}`),
  product_page: ({ locale, doc }) =>
    selectPath(locale, {}, `/${doc.uid || doc.id}`),
  partner_page: ({ locale, doc }) =>
    selectPath(locale, {}, `/${doc.uid || doc.id}`),
  solution_page: ({ locale, doc }) =>
    selectPath(locale, {}, `/${doc.uid || doc.id}`),
  know_us_page: ({ locale, doc }) =>
    selectPath(locale, {}, `/${doc.uid || doc.id}`),
  simple_page: ({ locale, doc }) =>
    selectPath(locale, {}, `/${doc.uid || doc.id}`),
  complex_page: ({ locale, doc }) =>
    selectPath(locale, {}, `/${doc.uid || doc.id}`),
  use_case: ({ locale, doc }) =>
    selectPath(locale, {}, `/${doc.uid || doc.id}`),
  use_case_archive: ({ locale, doc }) =>
    selectPath(locale, {}, `/${doc.uid || doc.id}`),
  white_paper: ({ locale, doc }) =>
    selectPath(locale, {}, `/${doc.uid || doc.id}`),
  white_paper_archive: ({ locale, doc }) =>
    selectPath(locale, {}, `/${doc.uid || doc.id}`),
  cookie_consent: () => '/',
  organization: () => '/',
  author: () => '/',
  site_info: () => '/',
  menu: () => '/',
  redirect: ({ locale, doc }) =>
    selectPath(locale, {}, `/redirect/${doc.uid || doc.id}`),
  entry_tag: ({ locale, doc }) =>
    selectPath(locale, {}, `/${doc.uid || doc.id}`),
  resellers_page: ({ locale, doc }) =>
    selectPath(locale, {}, `/${doc.uid || doc.id}`),
  reseller: () => '/',
  country: () => '/',
  reseller_form: () => '/',
};

const pathResolver: PathResolver = ({ locale }) => doc => {
  const resolvePath: ?PathResolverByType = pathResolvers[doc.type];
  if (!resolvePath) {
    if (contentTypes[doc.type]) {
      throw new Error(
        `expected a pathResolver function for prismic type ${doc.type}`,
      );
    } else {
      // eslint-disable-next-line no-console
      console.warn(oneLine`
        [config/prismic] No pathResolver function found for non-registered
        prismic type '${doc.type}'
      `);
    }
  }

  return resolvePath ? resolvePath({ locale, doc }) : '';
};

module.exports = pathResolver;
