// @flow
import * as React from 'react';
import noop from 'lodash/noop';
import type { HOC } from 'recompose';
import type { FieldProps } from 'formik';

export type FieldMapProps<E: {}> = (props: {
  ...$Exact<E>,
  ...$Exact<FieldProps>,
}) => $Shape<E>;

export type WithFieldMapProps<E: {}> = {
  ...$Exact<E>,
  mapProps?: FieldMapProps<E>,
};

export type WithFieldMapPropsHOC<E: FieldProps> = HOC<E, WithFieldMapProps<E>>;

export default function withFieldMapPropsHOC<
  Enhanced: FieldProps,
>(): WithFieldMapPropsHOC<Enhanced> {
  return Component => {
    const WithFieldMapPropsComponent = (props: WithFieldMapProps<Enhanced>) => {
      const { mapProps = noop, ...other } = props;

      return <Component {...other} {...mapProps(other)} />;
    };

    WithFieldMapPropsComponent.defaultProps = {
      mapProps: undefined,
    };

    return WithFieldMapPropsComponent;
  };
}
