// @flow
import * as React from 'react';
import SvgIcon, { type SvgIconProps } from '@material-ui/core/SvgIcon';

export type Props = SvgIconProps;

const InstagramIcon = ({ ...props }: Props): React.Node => {
  return (
    <SvgIcon width="64px" height="64px" viewBox="0 0 64 64" {...props}>
      <path
        d="M8.2,0h47.6c4.4-0.1,8.1,3.4,8.2,7.8c0,0.1,0,0.3,0,0.4v47.6c0.1,4.4-3.4,8.1-7.8,8.2c-0.1,0-0.3,0-0.4,0H8.2
        c-4.4,0.1-8.1-3.4-8.2-7.8c0-0.1,0-0.3,0-0.4V8.2C-0.1,3.8,3.4,0.1,7.8,0C7.9,0,8.1,0,8.2,0z M46.6,7.1c-1.6,0-2.9,1.3-2.9,2.9v0
        v6.9c0,1.6,1.3,2.9,2.9,2.9l0,0h7.2c1.6,0,2.9-1.3,2.9-2.9l0,0V10c0-1.6-1.3-2.9-2.9-2.9l0,0H46.6z M56.8,27.1h-5.6
        c0.5,1.8,0.8,3.6,0.8,5.5c0,10.6-8.9,19.3-19.9,19.3s-19.9-8.6-19.9-19.3c0-1.9,0.3-3.7,0.8-5.5H7.1v27c0,1.4,1.1,2.5,2.5,2.5
        c0,0,0,0,0,0h44.6c1.4,0,2.5-1.1,2.5-2.5c0,0,0,0,0,0C56.7,54.1,56.8,27.1,56.8,27.1z M32.1,19.4c-7-0.1-12.8,5.5-12.9,12.5
        c0.1,7,5.9,12.5,12.9,12.4c0,0,0,0,0,0c7,0.1,12.8-5.5,12.9-12.5C44.9,24.8,39.1,19.3,32.1,19.4C32.1,19.4,32.1,19.4,32.1,19.4z"
      />
    </SvgIcon>
  );
};

export default React.memo<Props>(InstagramIcon);
