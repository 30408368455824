// @flow
import * as React from 'react';
import withStyles from '@plugins/material-ui/hocs/withStyles';
import classnames from 'classnames';
import replace from 'lodash/fp/replace';

import hasRichTextFieldValue from '../../helpers/hasRichTextFieldValue';
import useDocumentLinkClick from './hooks/useDocumentLinkClick';

import styles from './styles';
import type { Props } from './types';

const RichText = ({
  Component,
  html,
  raw,
  text,
  className,
  classes,
  onDocumentLinkClick,
  ...props
}: Props): React.Node => {
  const ref = React.useRef<?HTMLElement>();

  useDocumentLinkClick({ html, ref, onClick: onDocumentLinkClick });

  const sanitizeText = replace(/&amp;/g, '&');

  return Component && hasRichTextFieldValue({ html, text }) ? (
    <Component
      {...props}
      className={classnames(classes.innerHtmlStyles, className)}
      dangerouslySetInnerHTML={{ __html: sanitizeText(html) }}
      ref={ref}
    />
  ) : null;
};

RichText.defaultProps = {
  Component: 'div',
  className: undefined,
};

export default withStyles<*, *, Props>(styles, { name: 'RichText' })(RichText);
