// @flow
import * as React from 'react';
import find from 'lodash/find';

import { i18n } from '../../i18n';
import resolvePath, { type ResolvePathNode } from '../helpers/resolvePath';

export type Config = {
  node: ?$Shape<{ [any]: any, ...$Exact<ResolvePathNode> }>,
};

export default function useNodePath({ node }: Config) {
  const locale = node
    ? find(i18n.getLocales(), { prismicLocale: node.lang })
    : null;

  return node && locale ? resolvePath({ locale, node }) : null;
}
