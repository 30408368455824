// @flow
import * as React from 'react';

import getPreviewData from '../helpers/getPreviewData';
import PreviewContext from '../context/PreviewContext';

export type Props = {
  children: React.Node,
};

const PreviewProvider = (props: Props) => {
  const previewData = getPreviewData();
  const isPreview = !!previewData;

  return (
    <PreviewContext.Provider
      {...props}
      value={React.useMemo(() => (isPreview ? previewData : undefined), [
        isPreview,
        previewData,
      ])}
    />
  );
};

export default PreviewProvider;
