// @flow

import type { Locale } from '../../i18n/types';
import type { PathResolver } from '../types';

import config$pathResolver from '../../../../config/prismic/pathResolver';

export type ResolvePathNode = {
  prismicId: string,
  uid: ?string,
  type: string,
  tags: Array<string>,
  lang: string,
};

export default function resolvePath({
  pathResolver = config$pathResolver,
  node: resolvePath$node,
  ...args
}: {
  pathResolver?: PathResolver,
  node: ResolvePathNode,
  locale: Locale,
}) {
  const node = {
    ...resolvePath$node,
    id: resolvePath$node.prismicId,
  };

  if (!node.type) {
    throw new TypeError('expected node to have a type');
  }

  return pathResolver({ node, ...args })(node);
}
