// @flow
import * as React from 'react';

import usePageContext from '~plugins/page-context/hooks/usePageContext';

import type { State, Action } from './types';
import AppBarActionsContext from './config/actionsContext';
import AppBarStateContext from './config/stateContext';
import reducer, { initialState } from './config/reducer';
import { MERGE_STATE } from './config/actions';
import sanitizeState from './helpers/sanitizeState';

export type Props = {|
  children: React.Node,
|};

const AppBarContextProvider = ({ children }: Props) => {
  const pageContext = usePageContext();
  const hasMergedState = React.useRef(false);
  const pageInitialState = React.useMemo(() => {
    hasMergedState.current = false;
    return { ...initialState, ...sanitizeState(pageContext) };
  }, [pageContext]);
  const [state, dispatch] = React.useReducer<State, Action>(
    reducer,
    pageInitialState,
  );

  const actions = React.useMemo(
    () => ({
      mergeState: partialState => {
        hasMergedState.current = true;
        return dispatch({ type: MERGE_STATE, payload: partialState });
      },
      resetState: () =>
        dispatch({ type: MERGE_STATE, payload: pageInitialState }),
    }),
    [dispatch, pageInitialState],
  );

  React.useEffect(() => {
    if (!hasMergedState.current) {
      actions.resetState();
    }
  }, [pageInitialState]);

  return (
    <AppBarActionsContext.Provider value={actions}>
      <AppBarStateContext.Provider value={state}>
        {children}
      </AppBarStateContext.Provider>
    </AppBarActionsContext.Provider>
  );
};

export default AppBarContextProvider;
