// @flow
import * as React from 'react';
import MuiSnackbar from '@material-ui/core/Snackbar';
import withStyles from '@plugins/material-ui/hocs/withStyles';

import RichText from '~plugins/prismic/components/RichText';
import * as variants from '~plugins/material-ui/modules/snackbar/config/variants';

import Portal from '~plugins/react/components/Portal';
import defaultIcons from './defaultIcons';
import styles, { omitOwnClasses } from './styles';
import type { Props } from './types';

const Snackbar = ({
  message,
  classes,
  variant,
  Icon,
  ...props
}: Props): React.Node => {
  const IconComponent =
    Icon === null
      ? null
      : Icon || defaultIcons[variant || variants.default] || null;

  return (
    <Portal
      target={typeof document === 'undefined' ? null : document.body}
      position="prepend"
    >
      <MuiSnackbar
        {...props}
        classes={React.useMemo(() => omitOwnClasses(classes), [classes])}
        ContentProps={{
          className: classes[variant || variants.default],
          'aria-describedby': 'snackbar-message',
        }}
        message={
          <div id="snackbar-message" className={classes.message}>
            {IconComponent ? <IconComponent className={classes.icon} /> : null}
            {typeof message === 'string' ? (
              <RichText html={message} />
            ) : (
              message
            )}
          </div>
        }
      />
    </Portal>
  );
};

Snackbar.defaultProps = {
  variant: variants.default,
  className: undefined,
  onClose: undefined,
};

const StyledSnackbar = withStyles<*, *, Props>(styles, { name: 'Snackbar' })(Snackbar);

export default StyledSnackbar;
