// @flow
import type { State, Action } from '../types';

import * as actions from './actions';

export const initialState: State = {
  linksColor: null,
};

export default function appBarReducer(
  state: State = initialState,
  action: Action,
) {
  switch (action.type) {
    case actions.MERGE_STATE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
