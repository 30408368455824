// @flow
import i18n from '../i18n';
import type { Locale } from '../types';
import usePageContext from '../../page-context/hooks/usePageContext';
import type { Page$Context } from '../../page-context/types';

export type PageTranslations = Array<{|
  path: string,
  current: boolean,
  exist: boolean,
  locale: Locale,
|}>;

export const getPageTranslations = (
  pageContext: Page$Context,
): PageTranslations =>
  i18n.getLocales().map(locale => ({
    current: locale.id === pageContext.localeId,
    exist: !!pageContext && !!pageContext.translations[locale.id],
    locale,
    path: (pageContext && pageContext.translations[locale.id]) || '',
  }));

export default function usePageTranslations(): PageTranslations {
  const pageContext = usePageContext();
  return getPageTranslations(pageContext);
}
