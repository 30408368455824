// @flow
import identity from 'lodash/identity';
import flow from 'lodash/fp/flow';
import { sanitizeHtmlId } from '~hocs/withBodySectionSlicePattern/helpers/html';

export type Config = {|
  prependHash?: boolean,
|};

export type GenerateTitleId = {
  (title: string, config?: Config): string,
  (title: null | void, config?: Config): void,
};

export const generateTitleId: GenerateTitleId = (title, config) =>
  title
    ? // $FlowFixMe --> Reason: conditional type return
      flow(
        sanitizeHtmlId,
        config?.prependHash ? anchor => `#${anchor}` : identity,
      )(title)
    : // $FlowFixMe --> Reason: conditional type return
      undefined;

export default generateTitleId;
