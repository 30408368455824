// @flow

export default {
  'contactForm.errors.email': 'Doit être une adresse e-mail valide',
  'contactForm.errors.required': 'Obligatoire',
  'contactForm.errors.mustAcceptPrivacyPolicy': 'Doit accepter la politique de confidentialité',
  'contactForm.labels.name': 'Nom',
  'contactForm.labels.body': 'Description',
  'contactForm.labels.email': 'Adresse e-mail',
  'contactForm.labels.phone': 'Téléphone',
  'contactForm.labels.privacyPolicy': 'Politique de confidentialité',
  'contactForm.labels.acceptPrivacyPolicy':
    'J`accepte {link, select, undefined {politique de confidentialité} other {{link}}}',
  'contactForm.labels.subject': 'Sujet',
  'contactForm.labels.submit': 'Envoyer',
  'contactForm.onError': 'Impossible d`envoyer la soumission.',
  'contactForm.onReject': 'Impossible d`envoyer la soumission.',
  'contactForm.onSuccess': 'Soumission envoyée!',
  'prismic.preview.nodePageInfo': `
    <p>
      <strong><code>{path}</code></strong> page d'aperçu
    </p>
    <p>Considérations relatives à la page d'aperçu:</p>
    <ul>
      {translationsCount, plural, =0 {} other {
      <li>les traductions ne se chargeront pas</li>
      } }
      <li>les données d'aperçu seront perdues lors du changement de page</li>
    </ul>
  `,
  'newsletterForm.errors.email': 'Doit être une adresse e-mail valide',
  'newsletterForm.errors.required': 'Obligatoire',
  'newsletterForm.labels.email': 'Adresse e-mail',
  'newsletterForm.labels.submit': 'Envoyer',
  'reseller.labels.types': 'TYPE DE REVENDEUR',
  'reseller.labels.resellerLocation': 'Voir l`emplacement',
  'reseller.labels.resellerLocations': 'Autres emplacements de {reseller}',
  'filter.labels.results': 'résultats',
  'filter.labels.postcode': 'Code postal',
  'filter.labels.enterPostcode': 'Veuillez entrer un code postal valide',
};
