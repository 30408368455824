const Cookies = require('js-cookie');

module.exports = function createCookieStorage() {
  return {
    get: ({ storageKey }) => Cookies.get(storageKey),
    set: ({ storageKey }, value) => {
      Cookies.set(storageKey, value);
    },
  };
};
