// @flow
import { graphql, useStaticQuery } from 'gatsby';

import useFindCurrentLocaleNode from '~plugins/prismic/hooks/useFindCurrentLocaleNode';
import type { Query, PrismicSiteInfo } from '~schema';

const query = graphql`
  query UseSiteInfoQuery {
    allPrismicSiteInfo {
      nodes {
        id
        lang
        data {
          appbar_actions {
            action_label {
              text
            }
            action_link {
              type
              link_type
              target
              url
            }
            action_link_section_title {
              text
            }
            action_variant
            action_menu_relation {
              document {
                ... on PrismicMenu {
                  id
                  data {
                    menu_actions {
                      action_label {
                        text
                      }
                      action_link {
                        type
                        link_type
                        target
                        url
                      }
                      action_link_section_title {
                        text
                      }
                    }
                  }
                }
              }
            }
          }
          footer_title {
            text
            html
          }
          footer_copyright {
            text
            html
          }
          footer_extra_text {
            text
            html
          }
          footer_tagline {
            text
            html
          }
          footer_iso_9001_link {
            type
            link_type
            target
            url
          }
          footer_gptw_link {
            type
            link_type
            target
            url
          }
          footer_extra_links {
            extra_link_label {
              text
            }
            extra_link {
              type
              link_type
              target
              url
            }
          }
          footer_actions {
            action_label {
              text
            }
            action_link {
              type
              link_type
              target
              url
            }
          }
          contact_address_icon {
            alt
            localFile {
              id
              childImageSharp {
                fluid(maxWidth: 50, quality: 85) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
          }
          contact_address_image {
            alt
            localFile {
              id
              childImageSharp {
                fluid(maxWidth: 180, quality: 85) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          contact_address {
            text
            html
          }
          contact_email_icon {
            alt
            localFile {
              id
              childImageSharp {
                fluid(maxWidth: 50, quality: 85) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
          }
          contact_email {
            text
            html
          }
          contact_phone_icon {
            alt
            localFile {
              id
              childImageSharp {
                fluid(maxWidth: 50, quality: 85) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
          }
          contact_phone {
            text
            html
          }
          social_links {
            social_label {
              text
            }
            social_link {
              link_type
              target
              url
            }
            social_icon
          }
          other_addresses {
            address_title {
              text
              html
            }
            address {
              text
              html
            }
            address_image {
              alt
              localFile {
                id
                childImageSharp {
                  fluid(maxWidth: 180, quality: 85) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
          other_logos {
            logo_image {
              alt
              localFile {
                id
                childImageSharp {
                  fluid(maxWidth: 300, quality: 85) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            logo_description {
              text
              html
            }
          }
        }
      }
    }
  }
`;

export default function useSiteInfo(): ?PrismicSiteInfo {
  return useFindCurrentLocaleNode<PrismicSiteInfo>({
    nodes: useStaticQuery<Query>(query).allPrismicSiteInfo?.nodes,
    toQuery: node => ({ prismicSiteInfo: node }),
    fromQuery: data => data?.prismicSiteInfo,
  });
}
