// @flow
import * as React from 'react';
import withStyles, {
  type StyleRulesCallback,
  type WithStyles,
} from '@plugins/material-ui/hocs/withStyles';
import type { Theme } from '@material-ui/core/styles/createMuiTheme';
import { useFormikContext } from 'formik';

import LoadingButton from '~components/LoadingButton';

export type ClassKey = 'disabled';

export type Props = {
  ...$Exact<WithStyles<ClassKey>>,

  className?: string,
};

export type Styles = StyleRulesCallback<Theme, Props, ClassKey>;

export const styles: Styles = unusedTheme => ({
  disabled: {},
});

const FormLoadingButton = props => {
  const { isSubmitting } = useFormikContext();

  return <LoadingButton loading={isSubmitting} classes={{}} {...props} />;
};

export default withStyles<*, *, Props>(styles, { name: 'FormLoadingButton' })(FormLoadingButton);
