// @flow

/** Any of the other types */
export const Any: 'Any' = 'Any';
/** Internal content */
export const Document: 'Document' = 'Document';
/** Internal media content */
export const Media: 'Media' = 'Media';
/** URL */
export const Web: 'Web' = 'Web';
