// @flow
import { graphql, useStaticQuery } from 'gatsby';

import useFindCurrentLocaleNode from '~plugins/prismic/hooks/useFindCurrentLocaleNode';
import type { Query, PrismicHomePage } from '~schema';

const query = graphql`
  query UseAppBarLogoStaticDataQuery {
    allPrismicHomePage {
      nodes {
        id
        type
        lang
        uid
      }
    }
  }
`;

export type HomeStaticData = {|
  prismicHomePage: ?PrismicHomePage,
|};

export default function useAppBarLogoStaticData(): HomeStaticData {
  const data = useStaticQuery<Query>(query);
  return {
    prismicHomePage: useFindCurrentLocaleNode<PrismicHomePage>({
      nodes: data.allPrismicHomePage?.nodes,
      toQuery: node => ({ prismicHomePage: node }),
      fromQuery: queryData => queryData?.prismicHomePage,
    }),
  };
}
