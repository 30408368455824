// @flow
import * as React from 'react';
import SvgIcon, { type SvgIconProps } from '@material-ui/core/SvgIcon';

export type Props = SvgIconProps;

const TwitterIcon = ({ ...props }: Props): React.Node => {
  return (
    <SvgIcon width="64px" height="52px" viewBox="0 0 64 52" {...props}>
      <path
        d="M64,6.2c-2.3,1.1-4.8,1.8-7.3,2.1c2.6-1.7,4.5-4.3,5.5-7.2C60.3,2.3,56.1,4,54,4c-2.6-2.5-6-4-9.6-4
        c-7.3,0-13.2,5.9-13.2,13.2c0,0,0,0,0,0c0,1,0.1,1.9,0.3,2.9c-9.9-0.3-21.5-5.2-28-13.7c-3.4,6-1.6,13.5,4,17.4
        C6,20,3.1,19.7,1.8,18.4C1.7,23,3.9,29.1,12,31.3c-1.6,0.8-4.3,0.6-5.5,0.4c0.4,3.9,5.9,9,11.8,9C16.2,43.1,9,47.6,0,46.2
        C6.2,50,13.4,52,20.7,52c20.3,0.1,36.9-16.2,37-36.6c0-0.7,0-1.4,0-2v-0.5C60.2,11.1,62.4,8.8,64,6.2z"
      />
    </SvgIcon>
  );
};

export default React.memo<Props>(TwitterIcon);
