// @flow
import type { Theme } from '@material-ui/core/styles/createMuiTheme';

import type { Theme$ComponentsProps } from '../types';

const getThemeResPaddingProps = (
  theme: Theme,
): $ElementType<Theme$ComponentsProps, 'ResPadding'> =>
  // $FlowFixMe --> Reason: improve custom theme props
  (theme.props: ?Theme$ComponentsProps)?.ResPadding;

export default getThemeResPaddingProps;
