// @flow
import React from 'react';

import type { ZohoCreateLeadValues } from '../types';

export type LeadCreationResponse = {
  data?: Array<?{
    code: string,
    details: {},
    message: string,
    status: string,
  }>,
};

export default function useLeadCreation(body: ZohoCreateLeadValues) {
  return fetch('/.netlify/functions/insertZohoLead', {
    method: 'POST',
    body: JSON.stringify(body),
  }).then(async response => ({
    response,
    payload: (await response.json(): LeadCreationResponse),
  }));
}
