// @flow
import React from 'react';

export default async function fetchRecaptchaValidation(token: string | null) {
  if (token) {
    const validation = await fetch('/.netlify/functions/validateRecaptcha', {
      method: 'POST',
      body: JSON.stringify({
        response: token,
      }),
    }).then(async response => ({ response, payload: await response.json() }));

    const { payload } = validation;

    if (!payload.success) {
      throw new Error('Invalid recaptcha validation');
    }
  }
}
