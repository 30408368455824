// @flow
import kebabCase from 'lodash/kebabCase';

export const sanitizeHtmlId = (id: string): string => kebabCase(id);

export const sanitizeHtmlClass = (className: string): string =>
  className
    .split(/\s+/)
    .map(sanitizeHtmlId)
    .join(' ');

export const extractHtmlCss = (css: ?string | { text?: ?string }): ?string =>
  typeof css === 'string' ? css : css?.text;

export const sanitizeHtmlCss = (css: string, { id }: { id: string }): string =>
  css.replace(/#\{id\}/g, `#${id}`);
