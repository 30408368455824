// @flow
import * as React from 'react';

import type { OnSubmitMapValues } from '../types';

export type Config<Values: {}> = {|
  onSubmitMapValues: void | OnSubmitMapValues<Values>,
  handleSubmitMapValues: OnSubmitMapValues<Values>,
|};

export default function useHandleOnSubmitMapValues<Values: {}>({
  onSubmitMapValues,
  handleSubmitMapValues,
}: Config<Values>): OnSubmitMapValues<Values> {
  return React.useCallback(
    values => {
      const maybeValues = onSubmitMapValues
        ? onSubmitMapValues(values)
        : undefined;
      const mappedValues = handleSubmitMapValues(values);
      return {
        subscribe: {
          ...mappedValues.subscribe,
          ...maybeValues?.subscribe,
        },
        createLead: {
          ...mappedValues.createLead,
          ...maybeValues?.createLead,
        },
      };
    },
    [onSubmitMapValues, handleSubmitMapValues],
  );
}
