// @flow
import * as React from 'react';
import withStyles, {
  type StyleRulesCallback,
  type WithStyles,
} from '@plugins/material-ui/hocs/withStyles';
import type { Theme } from '@material-ui/core/styles/createMuiTheme';
import Button, {
  type ButtonProps,
  type ButtonClassKey,
} from '@material-ui/core/Button';

export type ClassKey = ButtonClassKey;

export type Props = {
  ...$Exact<WithStyles<ClassKey>>,
  ...$Exact<$Diff<ButtonProps<*, {}>, {}>>,
  className?: string,
};

export type Styles = StyleRulesCallback<Theme, Props, ClassKey>;

export const styles: Styles = unusedTheme => ({
  root: {
    position: 'relative',
  },
  label: {},
  text: {},
  textPrimary: {},
  textSecondary: {},
  outlined: {
    // clipPath:
    //   'polygon(0% 10px,10px 0%,calc(100% - 10px) 0%,100% 10px,100% calc(100% - 10px),calc(100% - 10px) 100%,10px 100%,0 calc(100% - 10px))',
  },
  outlinedPrimary: {},
  outlinedSecondary: {},
  contained: {
    // clipPath:
    //   'polygon(0% 10px,10px 0%,calc(100% - 10px) 0%,100% 10px,100% calc(100% - 10px),calc(100% - 10px) 100%,10px 100%,0 calc(100% - 10px))',
  },
  containedPrimary: {},
  containedSecondary: {},
  focusVisible: {},
  disabled: {},
  colorInherit: {},
  sizeSmall: {},
  sizeLarge: {},
  fullWidth: {},
});

const StyledNotchedButton = ({
  color,
  variant,
  className,
  classes,
  ...props
}: Props): React.Node => {
  return (
    <Button
      className={className}
      classes={classes}
      variant={variant}
      color={color}
      {...props}
    />
  );
};

StyledNotchedButton.defaultProps = {
  className: undefined,
};

export default withStyles<*, *, Props>(styles, { name: 'StyledNotchedButton' })(StyledNotchedButton);
