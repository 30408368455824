// @flow
import React from 'react';

import type { ZohoSubscribeValues } from '../types';

export type SubscribeResponse = {
  code: string,
  details: {},
  message: string,
  status: string,
};

export default function useSubscribeToNewsletter(body: ZohoSubscribeValues) {
  return fetch('/.netlify/functions/subscribeToNewsletter', {
    method: 'POST',
    body: JSON.stringify(body),
  }).then(async response => ({
    response,
    payload: (await response.json(): SubscribeResponse),
  }));
}
