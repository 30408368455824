/* eslint-disable no-use-before-define */
// @flow
import * as React from 'react';
import withStyles from '@plugins/material-ui/hocs/withStyles';
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import MuiLink from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ButtonBase from '@material-ui/core/ButtonBase';
import classnames from 'classnames';
import map from 'lodash/map';
import split from 'lodash/split';

import SocialLinks from '~components/SocialLinks';
import NewsletterForm from '~components/NewsletterForm';
import SiteLogo from '~components/SiteLogo';
import useSiteInfo from '~hooks/useSiteInfo';
import useNewsletterForm from '~components/NewsletterForm/useNewsletterForm';
import useNewsletterFormValidationSchema from '~components/NewsletterForm/useNewsletterFormValidationSchema';
import InGroupIcon from '~components/icons/InGroupIcon';
import getNewsletterFormTrackingProps from '~helpers/getNewsletterFormTrackingProps';

import RichText, {
  hasRichTextFieldValue,
} from '~plugins/prismic/components/RichText';
import FieldLink, {
  hasLinkFieldValue,
} from '~plugins/prismic/components/FieldLink';
import NodeImage, {
  hasNodeImageValue,
} from '~plugins/prismic/components/NodeImage';
import ResPadding from '~plugins/material-ui/components/ResPadding';

import type { Props } from './types';
import styles from './styles';
import useFooterCopyright from './hooks/useFooterCopyright';

const NEWSLETTER_INPUT_SECTOR = 'Otros';
const newsletterFormTrackingProps = getNewsletterFormTrackingProps({
  inputSector: NEWSLETTER_INPUT_SECTOR,
});

const Footer = ({ className, classes, ...props }: Props): React.Node => {
  const copyright = useFooterCopyright();
  const siteInfo = useSiteInfo();
  const newsletterForm = useNewsletterForm();

  const taglineText =
    siteInfo &&
    siteInfo.data &&
    siteInfo.data.footer_tagline &&
    siteInfo.data.footer_tagline.text;
  const taglineTextArr = split(taglineText, /{company}/g);

  const showPymeIcon =
    process.env.GATSBY_FRONTEND_ENV_VAR_NAMESPACE === 'INVELON';

  const showFederIcon =
    process.env.GATSBY_FRONTEND_ENV_VAR_NAMESPACE === 'INVELON' ||
    process.env.GATSBY_FRONTEND_ENV_VAR_NAMESPACE === 'AURORA';

  const showGreatPlaceToWorkIcon =
    process.env.GATSBY_FRONTEND_ENV_VAR_NAMESPACE === 'INVELON' ||
    process.env.GATSBY_FRONTEND_ENV_VAR_NAMESPACE === 'INTECH';

  const getIso9001Icon = () => {
    switch (process.env.GATSBY_FRONTEND_ENV_VAR_NAMESPACE) {
      case 'INVELON': {
        return '/ISO-9001-invelon.png';
      }
      case 'INTECH': {
        return '/ISO-9001-intech.png';
      }
      default: {
        return null;
      }
    }
  };

  return (
    <div className={classnames(classes.root, className)} {...props}>
      <Container
        component={ResPadding}
        vertical
        maxWidth="xl"
        className={classes.wrapper}
      >
        <Grid container spacing={6} className={classes.itemGridContainer}>
          <Grid item xs={12} md={2} className={classes.itemLogoWrapper}>
            <SiteLogo colorHighlight="white" className={classes.siteLogo} />
            {siteInfo?.data?.footer_extra_text ? (
              <RichText
                {...siteInfo?.data?.footer_extra_text}
                className={classes.extraText}
              />
            ) : null}
            <div className={classes.itemLogoWrapperInner}>
              {hasLinkFieldValue(siteInfo?.data?.footer_iso_9001_link) && (
                <div className={classes.pymeWrapperDesktop}>
                  <ButtonBase
                    component={FieldLink}
                    field={siteInfo?.data?.footer_iso_9001_link}
                    aria-label="ISO 9001 Certification"
                  >
                    <img
                      src={getIso9001Icon()}
                      alt=""
                      width="100%"
                      height="auto"
                      className={classes.iso9001Icon}
                    />
                  </ButtonBase>
                </div>
              )}
              {showGreatPlaceToWorkIcon && (
                <div className={classes.pymeWrapperDesktop}>
                  {hasLinkFieldValue(siteInfo?.data?.footer_gptw_link) && (
                    <ButtonBase
                      component={FieldLink}
                      field={siteInfo?.data?.footer_gptw_link}
                      aria-label="Great Place To Work Certification"
                    >
                      <img
                        src="/Great-Place-To-Work-icon2.png"
                        alt=""
                        width="100%"
                        height="auto"
                        className={classes.greatPlaceToWorkIcon}
                      />
                    </ButtonBase>
                  )}
                </div>
              )}
              {showPymeIcon ? (
                <div className={classes.pymeWrapperDesktop}>
                  <img
                    src="/PYME-icon.png"
                    alt=""
                    width="100%"
                    height="auto"
                    className={classes.pyme}
                  />
                </div>
              ) : null}
              {showFederIcon && (
                <div className={classes.pymeWrapperDesktop}>
                  <img
                    src="/FEDER-icon2.png"
                    alt=""
                    width="100%"
                    height="auto"
                    className={classes.federIcon}
                  />
                </div>
              )}
            </div>
          </Grid>
          <Grid item xs={12} md={10} className={classes.itemContentWrapper}>
            <Grid container spacing={6}>
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                className={classnames(
                  classes.itemContentWrapperInner,
                  classes.itemContentPrimaryWrapper,
                )}
              >
                <div className={classes.contactWrapper}>
                  {hasNodeImageValue(siteInfo?.data?.contact_address_image) && (
                    <NodeImage
                      data={siteInfo?.data?.contact_address_image}
                      className={classes.addressImage}
                    />
                  )}
                  <div>
                    <RichText
                      {...siteInfo?.data?.footer_title}
                      className={classes.footerTitle}
                    />
                    <div className={classes.contactInfo}>
                      <RichText {...siteInfo?.data?.contact_address} />
                    </div>
                    <div className={classes.contactInfo}>
                      {siteInfo?.data?.contact_phone_icon ? (
                        <NodeImage
                          data={siteInfo?.data?.contact_phone_icon}
                          className={classes.contactInfoIcon}
                        />
                      ) : null}
                      <RichText {...siteInfo?.data?.contact_phone} />
                    </div>
                    <div className={classes.contactInfo}>
                      {siteInfo?.data?.contact_phone_icon ? (
                        <NodeImage
                          data={siteInfo?.data?.contact_email_icon}
                          className={classes.contactInfoIcon}
                        />
                      ) : null}
                      <RichText {...siteInfo?.data?.contact_email} />
                    </div>
                  </div>
                </div>
                {siteInfo?.data?.other_addresses
                  ? map(
                      siteInfo?.data?.other_addresses,
                      (address, index: number) => (
                        <div key={index} className={classes.extraContactInfo}>
                          {hasNodeImageValue(address?.address_image) && (
                            <NodeImage
                              data={address?.address_image}
                              className={classes.addressImage}
                            />
                          )}
                          <div>
                            <RichText
                              {...address?.address_title}
                              className={classes.footerTitle}
                            />
                            <RichText {...address?.address} />
                          </div>
                        </div>
                      ),
                    )
                  : null}
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={3}
                className={classnames(
                  classes.itemContentWrapperInner,
                  classes.itemContentSecondaryWrapper,
                )}
              >
                <RichText
                  {...newsletterForm?.data?.title}
                  className={classes.footerTitle}
                />
                <RichText
                  {...newsletterForm?.data?.description}
                  className={classes.newsletterDescription}
                />
                <NewsletterForm
                  listKey={newsletterForm?.data?.zoho_listkey || undefined}
                  node={newsletterForm}
                  validationSchema={useNewsletterFormValidationSchema(
                    newsletterForm,
                  )}
                  {...newsletterFormTrackingProps}
                />
              </Grid>
              {siteInfo?.data?.other_logos &&
                siteInfo?.data?.other_logos?.length >= 1 && (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={3}
                    className={classes.extraLogos}
                  >
                    {map(siteInfo?.data?.other_logos, (logo, index: number) => (
                      <div key={index} className={classes.extraLogo}>
                        <RichText
                          {...logo?.logo_description}
                          className={classes.extraLogoDescription}
                        />
                        {hasNodeImageValue(logo?.logo_image) && (
                          <NodeImage
                            data={logo?.logo_image}
                            className={classes.extraLogoImage}
                          />
                        )}
                      </div>
                    ))}
                  </Grid>
                )}
              <Grid
                item
                xs={12}
                sm={6}
                md={2}
                className={classnames(
                  classes.itemContentWrapperInner,
                  classes.itemContentTertiaryWrapper,
                )}
              >
                {map(
                  siteInfo?.data?.footer_extra_links,
                  (link, index: number) =>
                    link &&
                    link.extra_link_label &&
                    link.extra_link_label.text ? (
                      <Button
                        key={index}
                        component={FieldLink}
                        field={link.extra_link}
                        aria-label={link.extra_link_label.text}
                        className={classes.extraLink}
                      >
                        {link.extra_link_label.text}
                      </Button>
                    ) : null,
                )}
              </Grid>
              <Grid container item xs={12} sm={6}>
                {showPymeIcon ? (
                  <Grid item xs={6} className={classes.pymeWrapperMobile}>
                    <img
                      src="/PYME-icon.png"
                      alt=""
                      width="100%"
                      height="auto"
                      className={classes.pyme}
                    />
                  </Grid>
                ) : null}
                {showFederIcon && (
                  <Grid item xs={6} className={classes.pymeWrapperMobile}>
                    <img
                      src="/FEDER-icon2.png"
                      alt=""
                      width="100%"
                      height="auto"
                      className={classes.federIcon}
                    />
                  </Grid>
                )}
                {hasLinkFieldValue(siteInfo?.data?.footer_iso_9001_link) && (
                  <Grid item xs={6} className={classes.pymeWrapperMobile}>
                    <ButtonBase
                      component={FieldLink}
                      field={siteInfo?.data?.footer_iso_9001_link}
                      aria-label="ISO 9001 Certification"
                    >
                      <img
                        src={getIso9001Icon()}
                        alt=""
                        width="100%"
                        height="auto"
                        className={classes.iso9001Icon}
                      />
                    </ButtonBase>
                  </Grid>
                )}
                {showGreatPlaceToWorkIcon && (
                  <Grid item xs={6} className={classes.pymeWrapperMobile}>
                    {hasLinkFieldValue(siteInfo?.data?.footer_gptw_link) && (
                      <ButtonBase
                        component={FieldLink}
                        field={siteInfo?.data?.footer_gptw_link}
                        aria-label="Great Place To Work Certification"
                      >
                        <img
                          src="/Great-Place-To-Work-icon2.png"
                          alt=""
                          width="100%"
                          height="auto"
                          className={classes.greatPlaceToWorkIcon}
                        />
                      </ButtonBase>
                    )}
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Divider variant="middle" className={classes.divider} />
        <div className={classes.bottomFooter}>
          <div className={classes.bottomFooterText}>
            <RichText {...copyright} className={classes.copyright} />
            <div className={classes.actions}>
              {map(siteInfo?.data?.footer_actions, (action, index: number) =>
                action && action.action_label && action.action_label.text ? (
                  <MuiLink
                    key={index}
                    component={FieldLink}
                    field={action.action_link}
                    aria-label={action.action_label?.text}
                    underline="none"
                    className={classes.actionLink}
                  >
                    {action.action_label?.text}
                  </MuiLink>
                ) : null,
              )}
            </div>
          </div>
          <SocialLinks
            classes={React.useMemo(
              () => ({
                iconButton: classes.socialLink,
              }),
              [classes],
            )}
            links={siteInfo?.data?.social_links}
            className={classes.socialLinks}
          />
        </div>
        {hasRichTextFieldValue(siteInfo?.data?.footer_tagline) ? (
          <>
            <Divider
              variant="middle"
              className={classnames(classes.divider, classes.taglineDivider)}
            />
            <div className={classes.footerTagline}>
              <span>{taglineTextArr[0]}</span>
              <a
                href="https://ingroup.biz/"
                aria-label="InGroup icon"
                target="'_blank'"
                disableRipple
              >
                <InGroupIcon />
              </a>
              <span>{taglineTextArr[1]}</span>
            </div>
          </>
        ) : null}
      </Container>
    </div>
  );
};

Footer.defaultProps = {
  className: undefined,
};

export default withStyles<*, *, Props>(styles, { name: 'Footer' })(Footer);
