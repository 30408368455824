// @flow
import * as React from 'react';
import noop from 'lodash/noop';

import type { Actions } from './types';

export default React.createContext<Actions>({
  merge: noop,
  setEntryTags: noop,
});
