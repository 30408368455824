// @flow
import * as React from 'react';
import type { ClassNameMap } from '@plugins/material-ui/hocs/withStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import type { Theme } from '@material-ui/core/styles/createMuiTheme';
import ButtonBase, {
  type ButtonBaseProps,
  type ButtonBaseClassKey,
} from '@material-ui/core/ButtonBase';

import SiteLogo, {
  type ClassKey as SiteLogoClassKey,
} from '~components/SiteLogo';
import NodeLink from '~plugins/prismic/components/NodeLink';

import useAppBarLogoStaticData from './hooks/useAppBarLogoStaticData';

export type ClassKey = SiteLogoClassKey;

export type Props = {
  ...$Exact<React.ElementConfig<typeof SiteLogo>>,
  buttonProps?: $Shape<ButtonBaseProps>,
  buttonClasses?: ClassNameMap<ButtonBaseClassKey>,
  color: 'default' | 'white',
};

const useButtonStyles = makeStyles((unusedTheme: Theme) => ({
  root: {
    '& svg[id="ATECA"]': {
      fontSize: '13rem',
    },
    '& svg[id="ATECA_IT"]': {
      fontSize: '12rem',
    },
    '& svg[id="INTECH"]': {
      fontSize: '11rem',
    },
  },
  disabled: {},
  focusVisible: {},
}));

const AppBarLogo = ({
  buttonClasses: props$buttonClasses,
  buttonProps: props$buttonProps,
  color,
  ...props
}: Props): React.Node => {
  const { prismicHomePage } = useAppBarLogoStaticData();

  const buttonProps = React.useMemo(
    () => ({
      'aria-label': 'Navigate home',
      ...props$buttonProps,
      classes: props$buttonClasses,
      component: NodeLink,
      node: prismicHomePage,
      disableRipple: true,
    }),
    [props$buttonProps, props$buttonClasses],
  );

  return (
    <ButtonBase {...buttonProps} classes={useButtonStyles(buttonProps)}>
      <SiteLogo colorHighlight={color} {...props} />
    </ButtonBase>
  );
};

AppBarLogo.defaultProps = {
  buttonProps: undefined,
  buttonClasses: undefined,
};

export default AppBarLogo;
