// @flow
import React from 'react';
import Helmet from 'react-helmet';

export type Props = {
  schemaKey: ?string,
  title: string,
  alternateName: string,
  description: string,
  url: string, // page url e.g. www.example.com/about
  author: { name: string, email: string },
  siteUrl: string, // base url e.g www.example.com
  siteName: string, // base url e.g www.example.com
  datePublished: string,
  dateModified: string,
  image: {
    url: string,
    alt: string,
    width: string,
    height: string,
  },
  isBlogPost: boolean,
  organization: {
    url: string,
    name: string,
    email: string,
    logo: {
      url: string,
      width: string,
      height: string,
      alt: string,
    },
  },
};

// let count = 0;

const SchemaOrg = ({
  schemaKey,
  author,
  siteUrl,
  siteName,
  datePublished,
  dateModified,
  alternateName,
  description,
  image,
  isBlogPost,
  organization,
  title,
  url,
}: Props) => {
  const baseSchema = [
    {
      '@context': 'http://schema.org',
      '@type': 'WebPage',
      '@id': `${siteUrl}#webpage`,
      url,
      name: title,
      alternateName,
      description,
      datePublished,
      dateModified,
      image: {
        '@type': 'ImageObject',
        url: image?.url,
        caption: image?.alt,
        width: image?.width,
        height: image?.height,
      },
      isPartOf: {
        '@type': 'WebSite',
        '@id': `${siteUrl}#website`,
        url: siteUrl,
        name: siteName,
      },
    },
    {
      '@context': 'http://schema.org',
      '@type': 'Organization',
      '@id': organization?.name,
      name: organization?.name,
      logo: {
        '@type': 'ImageObject',
        url: organization?.logo?.url,
        width: organization?.logo?.width,
        height: organization?.logo?.height,
      },
      email: organization?.email,
      url: organization?.url,
    },
  ];

  const schema = isBlogPost
    ? [
        ...baseSchema,
        {
          '@context': 'http://schema.org',
          '@type': 'BreadcrumbList',
          '@id': '#breadcrumbs',
          itemListElement: [
            {
              '@type': 'ListItem',
              position: 1,
              item: {
                '@id': url,
                name: title,
                image: image?.url,
              },
            },
          ],
        },
        {
          '@context': 'http://schema.org',
          '@type': 'BlogPosting',
          '@id': `${url}#blogposting`,
          url,
          name: title,
          alternateName: alternateName,
          headline: title,
          image: {
            '@type': 'ImageObject',
            url: image?.url,
            caption: image?.alt,
            width: image?.width,
            height: image?.height,
          },
          description,
          author: {
            '@type': 'Person',
            name: author?.name,
            email: author?.email,
          },
          publisher: {
            '@type': 'Organization',
            url: organization?.url,
            logo: {
              '@type': 'ImageObject',
              url: organization?.logo?.url,
              width: organization?.logo?.width,
              height: organization?.logo?.height,
            },
            name: organization?.name,
          },
          mainEntityOfPage: {
            '@type': 'WebPage',
            '@id': `${url}/`, // I think the / is important as we want to refer to the root page e.g. www.example.com/ which will have /
          },
          datePublished,
          dateModified,
        },
      ]
    : baseSchema;

  return schemaKey ? (
    // We have seen a bug where a render will generate conflicting data.
    // To avoid it we wait until all the data is available.
    // schemaKey is the Prismic graphql query node.id, this way we ensure the data is from Prismic is available
    <Helmet>
      <script type="application/ld+json">{JSON.stringify(schema)}</script>
    </Helmet>
  ) : null;
};

export default SchemaOrg;
