// @flow
import * as React from 'react';

import NodeLinkInner from './NodeLinkInner';
import NodeLinkErrorBoundary from './NodeLinkErrorBoundary';

export type Props = React.ElementConfig<typeof NodeLinkInner>;

const NodeLink = React.forwardRef<Props, *>(({ children, ...props }, ref) => (
  <NodeLinkErrorBoundary {...props} linkChildren={children} ref={ref}>
    <NodeLinkInner {...props}>{children}</NodeLinkInner>
  </NodeLinkErrorBoundary>
));

NodeLink.displayName = 'NodeLink';

export default NodeLink;
