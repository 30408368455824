// @flow
/* eslint-disable import/prefer-default-export */

/**
 * Needed to show & group TabbedCards slices
 */
export const SLICE_TABBED_CARD_GROUPED_NAME = 'tabbed_cards_grouped';

/** Name for the gclid browser storage key */
export const GCLID_STORAGE_NAME = 'gclid';
