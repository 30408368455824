// @flow
import { fade } from '@material-ui/core/styles/colorManipulator';

import type { Styles } from './types';

const styles: Styles = theme => ({
  root: {},
  wrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  logo: {
    pointerEvents: 'all',
    fontSize: '8rem',
    height: 'auto',
    [theme.breakpoints.down('xs')]: {
      fontSize: '7rem',
    },
  },
  actions: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-end',
  },
  link: {
    color: ({ scrolled }) => (scrolled ? theme.palette.common.black : ''),
    padding: '8px 12px',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  menuIconButton: {
    pointerEvents: 'all',
    color: ({ scrolled }) => (scrolled ? theme.palette.common.black : ''),
  },
  menuIcon: {},
  submenuBackground: {
    backgroundColor: ({ scrolled }) =>
      scrolled
        ? theme.palette.common.white
        : fade(theme.palette.common.white, 0.9),
  },
  submenuLink: {
    color: theme.palette.common.black,
    padding: '8px 12px',
    '&:hover': {
      color: theme.palette.primary.main,
      backgroundColor: 'transparent',
    },
  },
  colorDefault: {
    color: theme.palette.common.black,
  },
  colorWhite: {
    color: theme.palette.common.white,
  },
});

export default styles;
