/* eslint-disable no-use-before-define */
// @flow
import * as React from 'react';
import withStyles from '@plugins/material-ui/hocs/withStyles';
import { LayoutActions } from 'material-ui-layout';
import classnames from 'classnames';
import map from 'lodash/map';

import useSiteInfo from '~hooks/useSiteInfo';
import FieldLink from '~plugins/prismic/components/FieldLink';
import LanguagePickerButtons from '~components/i18n/LanguagePickerButtons';
import SocialLinks from '~components/SocialLinks';

import DrawerItem from './DrawerItem';

import type { Props } from './types';
import styles from './styles';

const Drawer = ({
  closeDrawer,
  closeDrawerOnClick,
  position,
  className,
  classes,
  ...props
}: Props): React.Node => {
  const siteInfo = useSiteInfo();
  const layoutActions = React.useContext(LayoutActions);

  const handleDrawerClose =
    position === 'right'
      ? layoutActions.handleRightDrawerClose
      : layoutActions.handleLeftDrawerClose;

  const handleDrawerItemClick = React.useCallback(
    () => handleDrawerClose && setTimeout(handleDrawerClose, 10),
    [handleDrawerClose],
  );

  return (
    <div className={classnames(classes.root, className)} {...props}>
      {siteInfo?.data?.appbar_actions?.length ? (
        <div className={classes.menuLinks}>
          {map(siteInfo?.data?.appbar_actions, (item, index: number) =>
            item &&
            item.action_label &&
            item.action_label.text &&
            item.action_link ? (
              <DrawerItem
                key={index}
                component={FieldLink}
                field={item.action_link}
                fieldAnchor={item.action_link_section_title?.text}
                label={item.action_label.text}
                onClick={handleDrawerItemClick}
                menuLink={item.action_menu_relation}
              />
            ) : null,
          )}
        </div>
      ) : null}

      <LanguagePickerButtons classes={{ root: classes.languagePicker }} />
      <SocialLinks
        classes={React.useMemo(
          () => ({
            iconButton: classes.socialLink,
          }),
          [classes],
        )}
        links={siteInfo?.data?.social_links}
        className={classes.socialLinks}
      />
    </div>
  );
};

Drawer.defaultProps = {
  className: undefined,
  closeDrawer: undefined,
  closeDrawerOnClick: undefined,
  position: undefined,
};

export default withStyles<*, *, Props>(styles, { name: 'Drawer' })(Drawer);
