// @flow
import * as React from 'react';

import { FormattedMessage } from 'react-intl';
import withStyles, {
  type WithStyles,
} from '@plugins/material-ui/hocs/withStyles';
import type { Theme } from '@material-ui/core/styles/createMuiTheme';
import TextField from '~plugins/form/components/TextField';
import type { NewsletterFormValues } from './types';
import Form, {
  type Props as FormProps,
} from '~plugins/zoho-form/components/Form';
import useHandleOnSubmitMapValues from '~plugins/zoho-form/hooks/useHandleOnSubmitMapValues';
import withPartialSuccessReponseSnackbar from '~hocs/withPartialSuccessReponseSnackbar';
import FormLoadingButton from '~components/FormLoadingButton';
import StyledTextField from '~components/StyledTextField';
import useRecaptcha from '~hooks/useRecaptcha';
import fetchRecaptchaValidation from '~helpers/fetchRecaptchaValidation';
import messages from './messages';
import type { PrismicNewsletterForm } from '~schema';
import handleSubmitMapValues from './helpers/handleSubmitMapValues';

const EnhancedForm = withPartialSuccessReponseSnackbar<
  NewsletterFormValues,
  FormProps<NewsletterFormValues>,
>()(Form);

const lightColor = '#FFF';

export type ClassKey = 'root' | 'checkbox' | 'action' | 'disabledButton';

const styles = (theme: Theme) => ({
  root: {},
  checkbox: {
    textAlign: 'left',
  },
  action: {
    marginTop: theme.spacing(2),
  },
  disabledButton: {
    backgroundColor: `${theme.palette.primary.dark} !important`,
  },
});

export type Props = {
  ...$Exact<WithStyles<ClassKey>>,
  ...$Exact<$Diff<FormProps<NewsletterFormValues>, { onSubmitMapValues: any }>>,
  node: ?PrismicNewsletterForm,
  className?: string,
  sliceColor?: string,
  inputSector: ?string,
  onSubmitMapValues?: $ElementType<
    FormProps<NewsletterFormValues>,
    'onSubmitMapValues',
  >,
};

const NewsletterForm = ({
  sliceColor,
  initialValues,
  node,
  inputSector,
  onSubmitMapValues,
  className,
  classes,
  ...props
}: Props) => {
  const [recaptcha, token] = useRecaptcha();

  const handleSubmit = React.useCallback(
    () => fetchRecaptchaValidation(token),
    [],
  );

  return (
    <EnhancedForm
      {...props}
      node={node}
      messages={messages}
      onSubmit={handleSubmit}
      onSubmitMapValues={useHandleOnSubmitMapValues({
        onSubmitMapValues,
        handleSubmitMapValues,
      })}
      initialValues={React.useMemo(
        () => ({
          email: '',
          inputSector: inputSector || null,
          ...initialValues,
        }),
        [initialValues],
      )}
      className={classes.root}
    >
      <StyledTextField
        type="email"
        name="email"
        TextField={TextField}
        label={
          node?.data?.label_email?.text || (
            <FormattedMessage {...messages.labels.email} />
          )
        }
        inputProps={{ 'aria-label': 'Email input field' }}
        color={lightColor}
        fullWidth
      />

      {recaptcha}

      <FormLoadingButton
        type="submit"
        color="primary"
        variant="contained"
        aria-label="download"
        spinnerColor="secondary"
        fullWidth
        classes={{ disabled: classes.disabledButton }}
        className={classes.action}
      >
        {node?.data?.label_submit?.text || (
          <FormattedMessage {...messages.labels.submit} />
        )}
      </FormLoadingButton>
    </EnhancedForm>
  );
};

NewsletterForm.defaultProps = {
  className: undefined,
  sliceColor: undefined,
  onSubmitMapValues: undefined,
};

export default withStyles<*, *, Props>(styles, { name: 'NewsletterForm' })(NewsletterForm);
