// @flow
import * as React from 'react';

export default function useIsAppBarScrolledStyled() {
  const [scrolledStyle, setScrolledStyle] = React.useState(false);
  const handleScroll = React.useCallback(() => {
    if (window.scrollY >= 70) {
      (setScrolledStyle(true));
    } else {
      setScrolledStyle(false);
    }
  }, []);
  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return function cleanup() {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);
  return scrolledStyle;
}
