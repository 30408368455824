// @flow
import type { Locale } from './types';

const { oneLine } = require('common-tags');
const map = require('lodash/map');
const flow = require('lodash/fp/flow');

/* eslint-disable prettier/prettier,implicit-arrow-linebreak */
const locales: Array<Locale> = [];

let defaultLocaleId: string | void;

const addLocales = (newLocales: Array<Locale>) => {
  newLocales.forEach(locale => {
    locales.push(locale);
  });
};

const getLocales = () => locales;

const getLocale = (localeId: string) => {
  return locales.find(locale => locale.id === localeId);
};

const setDefaultLocale = (locale: Locale | string) => {
  const localeId = typeof locale === 'string' ? locale : locale.id;
  if (getLocale(localeId)) {
    defaultLocaleId = localeId;
  } else {
    throw new Error(oneLine`
      Cannot set '${localeId}' as default locale because there is no
      locale registered with such id. Registered locales:
      ${map(locales, 'id').join(', ')}
    `);
  }
};

const getDefaultLocale = (): Locale | void => {
  return defaultLocaleId
    ? locales.find(locale => locale.id === defaultLocaleId)
    : locales[0];
};

const localizePath = (localeOrId: Locale | string, path: string) => {
  const defaultLocale = getDefaultLocale();
  const locale =
    typeof localeOrId === 'string' ? getLocale(localeOrId) : localeOrId;
  const path$sanitized = flow(
    p => (p.startsWith('/') ? p : `/${p}`),
    p => (p.endsWith('/') ? p : `${p}/`),
  )(path);
  return !locale || (defaultLocale && defaultLocale.id === locale.id)
    ? path$sanitized
    : `/${locale.pathPrefix}${path$sanitized}`;
};

const getPathLocalizations = (path: string): { [string]: string } =>
  getLocales().reduce(
    (paths, locale) => ({
      ...paths,
      [locale.id]: localizePath(locale, path),
    }),
    {},
  );

module.exports = {
  addLocales,
  getLocales,
  getLocale,
  setDefaultLocale,
  getDefaultLocale,
  localizePath,
  getPathLocalizations,
};
