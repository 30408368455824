/* eslint-disable no-nested-ternary */
// @flow
import * as React from 'react';
import Button from '@material-ui/core/Button';
import withStyles, {
  type WithStyles,
} from '@plugins/material-ui/hocs/withStyles';
import type { Theme } from '@material-ui/core/styles';
import classnames from 'classnames';
import Link from '~plugins/i18n/components/Link';
import usePageTranslations from '~plugins/i18n/hooks/usePageTranslations';

export type ClassKey = 'root' | 'listItem' | 'button' | 'active';

export type Props = {
  ...$Exact<WithStyles<ClassKey>>,
  className?: string,
};

const styles = (theme: Theme) => ({
  root: {
    margin: 0,
    padding: 8,
    display: 'flex',
  },
  listItem: {
    listStyle: 'none',
  },
  button: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    minWidth: 'unset',
  },
  active: {
    color: `${theme.palette.primary.main} !important`,
  },
});

const LanguagePickerButtons = ({ classes, className, ...props }: Props) => (
  <ul {...props} className={classnames(className, classes.root)}>
    {usePageTranslations().map(({ path, locale, current, exist }) => {
      return (
        <li key={locale.id} className={classes.listItem}>
          {current ? (
            <Button
              disableRipple
              variant="outlined"
              color="primary"
              className={classnames(classes.button, {
                [classes.active]: current,
              })}
            >
              {locale.id}
            </Button>
          ) : exist ? (
            <Button
              component={Link}
              disableRipple
              className={classes.button}
              to={path}
              localized
            >
              {locale.id}
            </Button>
          ) : null}
        </li>
      );
    })}
  </ul>
);

LanguagePickerButtons.defaultProps = {
  className: undefined,
};

export default withStyles<ClassKey, *, Props>(styles, { name: 'LanguagePickerButtons' })(LanguagePickerButtons);
