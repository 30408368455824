// @flow
import * as React from 'react';
import type { Theme } from '@material-ui/core/styles/createMuiTheme';
import type { Breakpoint } from '@material-ui/core/styles/createBreakpoints';
import castArray from 'lodash/castArray';

import getPaddingFactor from './getPaddingFactor';
import getThemeResPaddingProps from './getThemeResPaddingProps';

const getPaddingStyles = (
  theme: Theme,
  properties: string | Array<string> = 'padding',
  factor?: number = 1,
  createStyles?: ({ breakpoint: Breakpoint, theme: Theme, factor: number }) => {
    [string]: any,
  },
) =>
  theme.breakpoints.keys.reduce(
    (acc, breakpoint) => ({
      ...acc,
      [theme.breakpoints.up(breakpoint)]: {
        ...((createStyles && createStyles({ breakpoint, theme, factor })) ||
          undefined),
        ...castArray(properties).reduce(
          (acc2, property: string) => ({
            ...acc2,
            [property]: theme.spacing(
              getPaddingFactor(
                breakpoint,
                getThemeResPaddingProps(theme)?.breakpointFactors,
              ) * factor,
            ),
          }),
          {},
        ),
      },
    }),
    {},
  );

export default getPaddingStyles;
