// @flow
import * as React from 'react';

import useSiteInfo from '~hooks/useSiteInfo';
import newDate from '~helpers/newDate';
import type { PrismicStructuredTextType } from '~schema';

export default function useFooterCopyright() {
  const siteInfo = useSiteInfo();
  const rawText =
    (siteInfo && siteInfo.data && siteInfo.data.footer_copyright) ||
    undefined;

  const replacements = [
    [/{currentYear}/g, newDate().getFullYear().toString()],
  ];

  return React.useMemo(
    () =>
      replacements.reduce<PrismicStructuredTextType>(
        (acc, [regExp, value]) => ({
          ...acc,
          text: acc.text && acc.text.replace(regExp, value),
          html: acc.html && acc.html.replace(regExp, value),
        }),
        { ...rawText },
      ),
    [rawText],
  );
}
