// @flow
import sanitizeAppBarColor from '~components/AppBar/helpers/sanitizeColor';

const sanitizeState = (possibleState: mixed) =>
  possibleState && typeof possibleState === 'object'
    ? {
        linksColor: sanitizeAppBarColor(possibleState.linksColor) || null,
      }
    : {};

export default sanitizeState;
