// @flow
import * as React from 'react';
import { Link as GatsbyLink } from 'gatsby';
import AniLinkBase from 'gatsby-plugin-transition-link/AniLink';

const AniLink = React.forwardRef<React.ElementConfig<typeof GatsbyLink>, *>(
  function AniLinkRef(props, ref) {
    const envColor = process.env.GATSBY_THEME_PALETTE_PRIMARY_DARK;

    return (
      <AniLinkBase
        {...props}
        innerRef={ref}
        paintDrip
        direction="left"
        duration={0.9}
        hex={envColor}
      />
    );
  },
);

export default AniLink;
