// @flow
import find from 'lodash/find';

import type { Query, PrismicDocument } from '~schema';

import usePageContext from '../../page-context/hooks/usePageContext';
import usePreviewData from './usePreviewData';

export type Node = PrismicDocument & { [any]: any };

export type Edge<N: Node> = {
  [any]: any,
  node: N,
};

export type Config<N: Node> =
         | {|
             nodes?: void,
             edges: ?$ReadOnlyArray<?Edge<N>>,
             toQuery: (node: ?N) => $Shape<Query>,
             fromQuery: (query: ?$Shape<Query>) => ?N,
           |}
         | {|
             nodes: ?$ReadOnlyArray<?N>,
             edges?: void,
             toQuery: (node: ?N) => $Shape<Query>,
             fromQuery: (query: ?$Shape<Query>) => ?N,
           |};

export default function useFindCurrentLocaleNode<N: Node>(config: Config<N>) {
  const nodes =
    config.nodes || (config.edges && config.edges.map<?N>(edge => edge?.node));

  const { locale } = usePageContext();

  const currentLocaleNode = find(
    nodes,
    node => node?.lang === locale.prismicLocale,
  );

  return config.fromQuery(usePreviewData(config.toQuery(currentLocaleNode)));
}
