// @flow
import * as React from 'react';

import { PrismicLinkTypesValues } from '../../../config';
import onDocumentLinkClick from '../helpers/onDocumentLinkClick';

import type { Props } from '../types';

export type Config = {|
  html: ?string,
  ref: { current: ?HTMLElement },
  onClick?: $ElementType<Props, 'onDocumentLinkClick'>,
|};

export default function useDocumentLinkClick({
  html,
  ref,
  onClick = onDocumentLinkClick,
}: Config) {
  React.useEffect(() => {
    if (ref.current) {
      ref.current
        .querySelectorAll(
          `a[data-link-type="${PrismicLinkTypesValues.Document}"]`,
        )
        .forEach(element => {
          element.removeEventListener('click', onClick);
          element.addEventListener('click', onClick);
        });
    }
  }, [html, ref.current, onClick]);
}
