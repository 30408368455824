// @flow
import * as React from 'react';
import SvgIcon, { type SvgIconProps } from '@material-ui/core/SvgIcon';

export type Props = SvgIconProps;

const LinkedInIcon = ({ ...props }: Props): React.Node => {
  return (
    <SvgIcon width="64px" height="64px" viewBox="0 0 64 64" {...props}>
      <path d="M57.6,0H6.4A6.42,6.42,0,0,0,0,6.4V57.6A6.42,6.42,0,0,0,6.4,64H57.6A6.42,6.42,0,0,0,64,57.6V6.4A6.42,6.42,0,0,0,57.6,0ZM19.2,54.4H9.6V25.6h9.6ZM14.4,20.16a5.76,5.76,0,1,1,5.76-5.76A5.74,5.74,0,0,1,14.4,20.16Zm40,34.24H44.8v-17a4.8,4.8,0,0,0-9.6,0v17H25.6V25.6h9.6v3.84a10.34,10.34,0,0,1,8-4.48,11.34,11.34,0,0,1,11.2,11.2Z" />
    </SvgIcon>
  );
};

export default React.memo<Props>(LinkedInIcon);
