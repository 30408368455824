// @flow
import * as React from 'react';
import Image from 'gatsby-image/withIEPolyfill';
import { oneLine } from 'common-tags';

import FileImage from './FileImage';
import type {
  File,
  PrismicImageFixedType,
  PrismicImageFluidType,
} from '~schema';

export type ImageData = {
  dimensions?: ?{ [any]: any, width: number, height: number },
  alt?: ?string,
  copyright?: ?string,
  url?: ?string,
  localFile?: ?File,
  fluid?: ?$Shape<PrismicImageFluidType>,
  fixed?: ?$Shape<PrismicImageFixedType>,
};

export type Props = {
  ...$Exact<
    $Diff<
      React.ElementConfig<typeof FileImage>,
      {
        file: any,
      },
    >,
  >,
  data: ?ImageData,
  fluid?: ?$Shape<PrismicImageFluidType>,
  fixed?: ?$Shape<PrismicImageFixedType>,
};

export const hasNodeImageValue = (data: $ElementType<Props, 'data'>): boolean =>
  !!(data && data.url && data.dimensions) ||
  !!(
    data &&
    ((data.fluid && data.fluid.src) || (data.fixed && data.fixed.src))
  ) ||
  !!(data && data.localFile && data.localFile.childImageSharp);

const NodeImage = ({
  ImageComponent = Image,
  data,
  fixed: props$fixed,
  fluid: props$fluid,
  ...props
}: Props) => {
  const fixed = props$fixed || data?.fixed;
  const fluid = props$fluid || data?.fluid || (fixed ? undefined : {});

  if (!(hasNodeImageValue(data) || fixed || fluid)) {
    return null;
  }

  return data && data.localFile ? (
    <FileImage
      alt={data?.alt || ''}
      {...props}
      file={data?.localFile}
      ImageComponent={ImageComponent}
    />
  ) : (
    <ImageComponent
      alt={data?.alt || ''}
      {...props}
      fluid={
        fluid
          ? {
              ...(data && data.url
                ? {
                    src: data?.url || undefined,
                    srcSet: data?.url || undefined,
                  }
                : undefined),
              ...(data && data.dimensions
                ? {
                    aspectRatio: data.dimensions.width / data.dimensions.height,
                    sizes: oneLine`
                      (max-width: ${data.dimensions.width}) 100vw,
                      ${data.dimensions.width}
                    `,
                  }
                : undefined),
              ...fluid,
            }
          : undefined
      }
      fixed={
        fixed
          ? {
              ...(data && data.url
                ? {
                    src: data?.url || undefined,
                    srcSet: data?.url || undefined,
                  }
                : undefined),
              ...data?.dimensions,
              ...fixed,
            }
          : undefined
      }
    />
  );
};

NodeImage.defaultProps = {
  fluid: undefined,
  fixed: undefined,
};

// eslint-disable-next-line react/display-name
export default React.forwardRef<React.ElementConfig<typeof NodeImage>, *>(
  (props, ref) => <NodeImage innerRef={ref} {...props} />,
);
