// @flow
/* eslint-disable no-use-before-define */
import * as React from 'react';
import withStyles, {
  type StyleRulesCallback,
  type WithStyles,
} from '@plugins/material-ui/hocs/withStyles';
import type { Theme } from '@material-ui/core/styles/createMuiTheme';
import Button, {
  type ButtonClassKey,
  type ButtonProps,
} from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import DownArrow from '@material-ui/icons/KeyboardArrowDown';
import classnames from 'classnames';

import FieldLink, {
  type Props as FieldLinkProps,
} from '~plugins/prismic/components/FieldLink';
import type { PrismicLinkType } from '~schema';

import getMenuType from '../../helpers/getMenuType';
import isMaxLinksPerColumnReached from './helpers/isMaxLinksPerColumnReached';
import AppBarMenuLinks from './AppBarMenuLinks';

export type ClassKey =
  | ButtonClassKey
  | 'iconButton'
  | 'popper'
  | 'paper'
  | 'menuItem';

export type Props = ButtonProps<
  'button',
  {
    ...$Exact<WithStyles<ClassKey>>,
    className?: string,
    link: ?PrismicLinkType,
    anchor: ?$ElementType<FieldLinkProps, 'fieldAnchor'>,
    children: ?React.Node,
    menuLink: ?PrismicLinkType,
    maxLinksPerColumn?: number,
    MenuProps?: $Diff<
      React.ElementConfig<typeof Menu>,
      {
        open: any,
        anchorEl: any,
        onClose: any,
      },
    >,
  },
>;

export type Styles = StyleRulesCallback<Theme, Props, ClassKey>;

const defaultMaxLinksPerColumn = 5;

export const styles: Styles = theme => ({
  root: {},
  label: {},
  text: {},
  textPrimary: {},
  textSecondary: {},
  outlined: {
    border: `2px solid ${theme.palette.primary.main}`,
    color: `${theme.palette.primary.main} !important`,
    margin: theme.spacing(1),
  },
  outlinedPrimary: {},
  outlinedSecondary: {},
  contained: {
    backgroundColor: theme.palette.primary.main,
    color: `${theme.palette.common.white} !important`,
    boxShadow: 'none !important',
    margin: theme.spacing(1),
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  containedPrimary: {},
  containedSecondary: {},
  focusVisible: {},
  disabled: {},
  colorInherit: {},
  sizeSmall: {},
  sizeLarge: {},
  fullWidth: {},
  iconButton: {
    padding: 0,
  },
  popper: {
    zIndex: 1,
    maxWidth: ({ menuLink, maxLinksPerColumn = defaultMaxLinksPerColumn }) =>
      isMaxLinksPerColumnReached({ menuLink, maxLinksPerColumn }) ? 450 : 220,
  },
  paper: {},
  menuItem: {
    '& a' : {
      minHeight: 'auto',
      lineHeight: 'normal'
    }
  },
});

const AppBarLink = ({
  link,
  anchor,
  variant,
  color,
  menuLink,
  maxLinksPerColumn,
  MenuProps,
  className,
  classes,
  children,
  ...props
}: Props): React.Node => {
  const anchorRef = React.useRef(null);
  const menu = getMenuType(menuLink);

  const [open, setOpen] = React.useState(false);
  const handleOpen = React.useCallback(
    (event: SyntheticEvent<HTMLButtonElement>) => {
      event.preventDefault();
      setOpen(true);
    },
    [],
  );
  const handleClose = React.useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <>
      <Button
        ref={anchorRef}
        className={classnames(classes.root, className)}
        {...props}
        classes={{
          outlined: classes.outlined,
          contained: classes.contained,
        }}
        component={FieldLink}
        field={link}
        fieldAnchor={anchor}
        aria-label={children}
        {...(!!menu
          ? {
              endIcon: (
                <IconButton
                  onClick={handleOpen}
                  // TODO: translate
                  aria-label="Open menu"
                  color="inherit"
                  className={classes.iconButton}
                >
                  <DownArrow color="inherit" />
                </IconButton>
              ),
              onMouseEnter: handleOpen,
              onMouseLeave: open ? handleClose : undefined,
            }
          : null)}
        variant={variant}
        color={color}
      >
        {children}
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        onMouseEnter={handleOpen}
        onMouseLeave={handleClose}
        role={undefined}
        disablePortal
        className={classes.popper}
        {...MenuProps}
      >
        <Paper square className={classes.paper}>
          <AppBarMenuLinks
            menuLinks={menu}
            gridItemProps={
              isMaxLinksPerColumnReached({
                menuLink,
                maxLinksPerColumn: defaultMaxLinksPerColumn,
              })
                ? { md: 4 }
                : { md: 12 }
            }
            classes={{ item: classes.menuItem }}
          />
        </Paper>
      </Popper>
    </>
  );
};

AppBarLink.defaultProps = {
  className: undefined,
};

export default withStyles<*, *, Props>(styles, { name: 'AppBarLink' })(AppBarLink);
