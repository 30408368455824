/* eslint-disable no-use-before-define */
// @flow
import * as React from 'react';
import queryString from 'query-string';
import castArray from 'lodash/castArray';
import compact from 'lodash/compact';
import { navigate } from 'gatsby';

import FiltersStateContext, { defaultState } from './FiltersStateContext';
import FiltersActionsContext from './FiltersActionsContext';

export type Mode = 'local' | 'search';

export type Props = {|
  children: React.Node,
  mode: Mode,
  location: Location,
|};

const FiltersProvider = ({ children, mode, location }: Props) => {
  const [filters, setFilters] = useFilters({ mode, location });

  return (
    <FiltersStateContext.Provider value={filters}>
      <FiltersActionsContext.Provider
        value={React.useMemo(() => {
          const merge = nextFilters => {
            setFilters(prevFilters => ({
              ...prevFilters,
              ...nextFilters,
            }));
          };
          return {
            merge,
            setEntryTags: nextEntryTags => {
              merge({ entryTags: nextEntryTags });
            },
          };
        }, [setFilters])}
      >
        {children}
      </FiltersActionsContext.Provider>
    </FiltersStateContext.Provider>
  );
};

export default FiltersProvider;

function useFilters({ mode, location }) {
  return {
    local: React.useState(defaultState),
    search: useFiltersSearch({ mode, location }),
  }[mode || ('local': Mode)];
}

function useFiltersSearch({ mode, location }) {
  const [filters, setFilters] = React.useState(defaultState);

  const { search, pathname } = location || {};

  React.useEffect(() => {
    if (mode === ('search': Mode)) {
      setFilters(sanitizeFiltersFromSearch(search));
    }
  }, [search, mode]);

  return [
    filters,
    React.useCallback<typeof setFilters>(
      nextOrFn => {
        if (pathname) {
          const nextFilters =
            typeof nextOrFn === 'function' ? nextOrFn(filters) : nextOrFn;

          navigate(
            compact([
              pathname,
              queryString.stringify(nextFilters, {
                arrayFormat: 'bracket',
              }),
            ]).join('?'),
            {
              replace: true,
            },
          );
        }
      },
      [filters, pathname],
    ),
  ];
}

function sanitizeFiltersFromSearch(search) {
  const parsedSearch = queryString.parse(search, {
    arrayFormat: 'bracket',
  });

  return {
    entryTags: parsedSearch.entryTags
      ? castArray(parsedSearch.entryTags).map(sanitizeSearchParam)
      : defaultState.entryTags,
  };
}

function sanitizeSearchParam(value) {
  return typeof value === 'string' ? value.replace(/\/$/, '') : value;
}
