// @flow

export type Config = {|
  formName?: string,
  contentSource?: string,
  inputSector: string,
  appendInputSectorToContentSource?: boolean,
|};

export type NewsletterFormTrackingProps = {|
  formName: string,
  contentSource: string,
  inputSector: string,
|};

export const FORM_NAME = 'Newsletter';

export const NAMESPACE = process.env.GATSBY_FRONTEND_ENV_VAR_NAMESPACE;

if (!NAMESPACE) {
  throw new Error('Missing process.env.GATSBY_FRONTEND_ENV_VAR_NAMESPACE');
}

export const CONTENT_SOURCE = 'Newsletter Web';

const getNewsletterFormTrackingProps = ({
  formName = FORM_NAME,
  contentSource = CONTENT_SOURCE,
  inputSector,
  appendInputSectorToContentSource,
}: Config): NewsletterFormTrackingProps => {
  const contentSourceNamespaced = `${NAMESPACE} - ${contentSource}`;
  return {
    formName,
    contentSource: appendInputSectorToContentSource
      ? `${contentSourceNamespaced} ${inputSector}`
      : contentSourceNamespaced,
    inputSector: inputSector,
  };
};

export default getNewsletterFormTrackingProps;
