// @flow
/* eslint-disable react/destructuring-assignment */
import * as React from 'react';
// eslint-disable-next-line no-restricted-imports
import withMuiStyles, {
  type Styles,
  type WithStylesOptions,
  type ClassNameMap,
  type ClassKeyOfStyles$ClassKey,
  type StyledComponentProps,
} from '@material-ui/core/styles/withStyles';
import type { Theme as MuiTheme } from '@material-ui/core/styles/createMuiTheme';

// eslint-disable-next-line no-restricted-imports
export * from '@material-ui/core/styles/withStyles';

function withStyles<
  ClassKey: string,
  Options: $Diff<WithStylesOptions, { name: any }> & {
    // $FlowFixMe --> Reason: badly defined types
    name: string,
    // $FlowFixMe --> Reason: badly defined types
    withTheme?: false,
  },
  Props: {},
>(
  style: Styles<MuiTheme, Props, ClassKey>,
  options: Options,
): <Comp: React$ComponentType<Props>>(
  Component: Comp,
) => React$ComponentType<
  $Diff<React$ElementConfig<Comp>, { classes: any }> &
    StyledComponentProps<ClassKey>,
> {
  return Component => {
    const _withMuiStyles = withMuiStyles<ClassKey, Options, Props>(style, {
      ...options,
      withTheme: false,
    });
    const WithStylesComponent = (props: Props) => {
      const classes: ClassNameMap<ClassKeyOfStyles$ClassKey<ClassKey>> =
        // $FlowFixMe --> Reason: badly defined types
        props.classes || {};
      return (
        <Component
          {...props}
          classes={React.useMemo(
            () =>
              Object.keys(classes || {}).reduce<typeof classes>((acc, key) => {
                const classKey: ClassKey =
                  // $FlowFixMe --> Reason: badly defined types
                  key;
                acc[classKey] = `${options.name}-${classKey} ${classes[classKey]}`;
                return acc;
              }, {}),
            [classes],
          )}
        />
      );
    };
    return _withMuiStyles(WithStylesComponent);
  };
}

export default withStyles;
