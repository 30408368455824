// @flow
import type { Styles } from './types';

const styles: Styles = theme => ({
  root: {},
  text: {
    fill: ({ colorHighlight }) =>
      colorHighlight === 'white'
        ? theme.palette.common.white
        : theme.palette.common.black,
  },
  customText: {
    fill: ({ colorHighlight }) =>
      colorHighlight === 'white' ? theme.palette.common.white : '#656b71',
  },
  icon: {
    fill: process.env.GATSBY_ICON_COLOR ?? '#D50037',
  },
  customIcon: {
    fill: ({ colorHighlight }) =>
      colorHighlight === 'white'
        ? theme.palette.common.white
        : '#004A98',
  },
});

export default styles;
