const React = require('react');
const { navigate } = require('gatsby');

const {
  getCurrentLocale,
} = require('../../../../src/plugins/i18n/hooks/useCurrentLocale');
const {
  getPageTranslations,
} = require('../../../../src/plugins/i18n/hooks/usePageTranslations');

const getBrowserLocales = require('../helpers/getBrowserLocales');
const filterPageTranslations = require('../helpers/filterPageTranslations');

module.exports = function IntlPageRedirect({
  storage,
  pageContext,
  pluginOptions,
}) {
  const currentLocale = getCurrentLocale(pageContext);

  React.useEffect(() => {
    if (!storage.get(pluginOptions)) {
      const browserLocales = getBrowserLocales();
      const pageTranslations = filterPageTranslations(
        getPageTranslations(pageContext),
        browserLocales,
      );
      const [pageTranslation] = pageTranslations;

      if (
        pageTranslations.length &&
        !pageTranslations.find(({ current }) => current)
      ) {
        navigate(pageTranslation.path, { replace: true });
      }
    }
    storage.set(pluginOptions, currentLocale.id);
  }, [currentLocale]);

  return null;
};
