// @flow

export default {
  'contactForm.errors.email':
    'Debe ser una dirección de correo electrónico válida',
  'contactForm.errors.required': 'Obligatorio',
  'contactForm.errors.mustAcceptPrivacyPolicy':
    'Es obligatiorio aceptar la política de privacidad',
  'contactForm.labels.body': 'Descripción',
  'contactForm.labels.email': 'Correo electrónico',
  'contactForm.labels.privacyPolicy': 'política de privacidad',
  'contactForm.labels.acceptPrivacyPolicy':
    'Acepto la {link, select, undefined {política de privacidad} other {{link}}}',
  'contactForm.labels.subject': 'Asunto',
  'contactForm.labels.submit': 'Enviar',
  'contactForm.onError': 'No se ha podido enviar el formulario.',
  'contactForm.onReject': 'No se ha podido enviar el formulario.',
  'contactForm.onSuccess': 'Formulario enviado!',
  'prismic.preview.nodePageInfo': `
    <p>
      Página de previsualización de <strong><code>{path}</code></strong>
    </p>
    <p>Consideraciones de las páginas de previsualización:</p>
    <ul>
      {translationsCount, plural, =0 {} other {
      <li>las traducciones no se cargaran</li>
      } }
      <li>los datos de la previsualización se perderan al cambiar de página</li>
    </ul>
  `,
};
