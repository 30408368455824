// @flow
import * as React from 'react';
import withStyles from '@plugins/material-ui/hocs/withStyles';
import Toolbar from '@material-ui/core/Toolbar';
import Container from '@material-ui/core/Container';
import IconButton from '@material-ui/core/IconButton';
import Hidden from '@material-ui/core/Hidden';
import MenuIcon from '@material-ui/icons/Menu';
import classnames from 'classnames';
import map from 'lodash/map';

import LanguagePickerButtons from '~components/i18n/LanguagePickerButtons';
import useSiteInfo from '~hooks/useSiteInfo';

import type { Props } from './types';
import styles from './styles';
import AppBarLogo from './AppBarLogo';
import AppBarLink from './AppBarLink';
import useAppBarState from './hooks/useAppBarState';
import useLayoutActionsContext from './hooks/useLayoutActionsContext';
import getButtonVariant from './helpers/getButtonVariant';

const AppBar = ({
  toggleRightDrawer: props$toggleRightDrawer,
  toggleLeftDrawer: props$toggleLeftDrawer,
  scrolled,
  className,
  classes,
  ...props
}: Props): React.Node => {
  const siteInfo = useSiteInfo();
  const { linksColor } = useAppBarState();

  const {
    toggleRightDrawer: context$toggleRightDrawer,
  } = useLayoutActionsContext();

  const handleIconClick = props$toggleRightDrawer || context$toggleRightDrawer;

  const linksColorClassName = linksColor
    ? { default: classes.colorDefault, white: classes.colorWhite }[linksColor]
    : undefined;

  return (
    <Toolbar
      component={Container}
      maxWidth="xl"
      className={classnames(classes.root, className)}
      {...props}
    >
      <div className={classes.wrapper}>
        <AppBarLogo
          className={classes.logo}
          color={
            scrolled
              ? 'default'
              : (linksColor &&
                  {
                    default: 'default',
                    white: 'white',
                  }[linksColor]) ||
                'default'
          }
        />
        <Hidden smDown implementation="css">
          <div className={classes.actions}>
            {map(siteInfo?.data?.appbar_actions, (action, index: number) =>
              action && action.action_label && action.action_label.text ? (
                <AppBarLink
                  key={index}
                  link={action.action_link}
                  anchor={action.action_link_section_title?.text}
                  variant={getButtonVariant(action.action_variant)}
                  menuLink={action.action_menu_relation}
                  className={classnames(classes.link, linksColorClassName)}
                  classes={{
                    paper: classes.submenuBackground,
                    menuItem: classes.submenuLink,
                  }}
                >
                  {action.action_label?.text}
                </AppBarLink>
              ) : null,
            )}
            <LanguagePickerButtons />
          </div>
        </Hidden>
        <Hidden mdUp implementation="css">
          <IconButton
            disableRipple
            aria-label="Menu Icon"
            onClick={handleIconClick}
            className={classnames(
              classes.menuIconButton,
              linksColor
                ? {
                    default: classes.colorDefault,
                    white: classes.colorWhite,
                  }[linksColor]
                : undefined,
            )}
          >
            <MenuIcon className={classes.menuIcon} />
          </IconButton>
        </Hidden>
      </div>
    </Toolbar>
  );
};

AppBar.defaultProps = {
  className: undefined,
};

export default withStyles<*, *, Props>(styles, { name: 'AppBar' })(AppBar);
