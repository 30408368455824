// @flow
import * as React from 'react';
import { Field } from 'formik';
import { TextField as FormikTextField } from 'formik-material-ui';

import withFieldMapProps from '../hocs/withFieldMapProps';
import type { FormComponentProps } from '../types';

const EnhancedFormikTextField = withFieldMapProps<
  React.ElementConfig<typeof FormikTextField>,
>()(FormikTextField);

export type Props = FormComponentProps<typeof EnhancedFormikTextField, {}>;

const TextField = (props: Props) => (
  <Field component={EnhancedFormikTextField} {...props} />
);

export default TextField;
