// @flow
import type { Styles } from './types';

const styles: Styles = unusedTheme => ({
  innerHtmlStyles: {
    '& p:not([class*="MuiTypography"])': {
      marginBefore: 0,
      marginAfter: 0,
      marginBlockStart: 0,
      marginBlockEnd: 0,
      marginInlineStart: 0,
      marginInlineEnd: 0,
    },
  },
});

export default styles;
