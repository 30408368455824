// @flow
import * as React from 'react';
import noop from 'lodash/noop';
import type { HOC } from 'recompose';
import type { FormikActions } from 'formik';
import { useIntl, type MessageDescriptor } from 'react-intl';

import useSnackbarContext from '~plugins/material-ui/modules/snackbar/hooks/useSnackbarContext';
import type { Props } from '~plugins/zoho-form/components/Form';
import useNodeSnackbarMessages, {
  type Node,
} from '~plugins/form/hooks/useNodeSnackbarMessages';

export type Messages = {
  onSuccess?: MessageDescriptor | string,
  onError?: MessageDescriptor | string,
  onReject?: MessageDescriptor | string,
  emailAlreadySubscribed?: MessageDescriptor | string,
};

type BaseProps<Values: {}, E: {}> = {
  ...$Exact<E>,
  onSuccess?: $ElementType<Props<Values>, 'onSuccess'>,
  onError?: $ElementType<Props<Values>, 'onError'>,
  onPartialSuccess?: $ElementType<Props<Values>, 'onPartialSuccess'>,
  onReject?: $ElementType<Props<Values>, 'onReject'>,
};

type EnhancedProps<Values: {}, E: {}> = E & {
  onSuccess?: $ElementType<Props<Values>, 'onSuccess'>,
  onSuccessMessage?: (
    Values,
    FormikActions<Values>,
    ?Array<?Response>,
  ) => ?string,
  onError?: $ElementType<Props<Values>, 'onError'>,
  onErrorMessage?: (Response, Values, FormikActions<Values>) => ?string,
  onReject?: $ElementType<Props<Values>, 'onReject'>,
  onRejectMessage?: (Error, Values, FormikActions<Values>) => ?string,
  onPartialSuccess?: $ElementType<Props<Values>, 'onPartialSuccess'>,
  onPartialSuccessMessage?: (
    Values,
    FormikActions<Values>,
    ?Array<?Response>,
  ) => ?string,
  messages?: Messages,
  node?: ?Node,
  formName?: ?string,
};

export type WithPartialSuccessResponseSnackbarHOC<Values: {}, E: {}> = HOC<
  BaseProps<Values, E>,
  EnhancedProps<Values, E>,
>;

export default function withPartialSuccessResponseSnackbarHOC<
  Values: {},
  Enhanced: {},
>(): WithPartialSuccessResponseSnackbarHOC<Values, Enhanced> {
  return Component => {
    const WithPartialSuccessResponseSnackbar = (
      props: EnhancedProps<Values, Enhanced>,
    ) => {
      const intl = useIntl();
      const snackbar = useSnackbarContext();
      const {
        onSuccess = noop,
        onSuccessMessage = noop,
        onError = noop,
        onErrorMessage = noop,
        onReject = noop,
        onRejectMessage = noop,
        onPartialSuccess = noop,
        onPartialSuccessMessage = noop,
        messages: props$messages = {},
        node,
        formName,
        ...other
      } = props;

      const node$messages = useNodeSnackbarMessages(node);

      const takeMessage = React.useCallback(
        (message: ?MessageDescriptor | ?string): ?string =>
          typeof message === 'string'
            ? message
            : (message && intl.formatMessage(message)) || null,
        [intl.locale],
      );
      const handleEmailSubscribed = (responses: ?Array<?Response>) => {
        if (
          responses &&
          responses.some(response => response && response.status === 409)
        ) {
          return takeMessage(
            node?.data?.event_subscribed?.html ||
              node?.data?.event_subscribed?.text ||
              props$messages?.emailAlreadySubscribed,
          );
        }
        return undefined;
      };

      return (
        <Component
          {...other}
          onSuccess={React.useCallback(
            (...args) => {
              const message =
                onSuccessMessage(...args) ||
                takeMessage(
                  node$messages?.onSuccess || props$messages?.onSuccess,
                );
              if (message)
                snackbar.show({
                  message,
                  variant: 'success',
                  className: formName ? `${formName}-success` : undefined,
                });
              onSuccess(...args);
            },
            [onSuccess, onSuccessMessage],
          )}
          onError={React.useCallback(
            (...args) => {
              const message =
                onErrorMessage(...args) ||
                takeMessage(node$messages?.onError || props$messages?.onError);
              if (message) {
                snackbar.show({
                  message,
                  variant: 'error',
                  className: formName ? `${formName}-error` : undefined,
                });
              }

              onError(...args);
            },
            [onError, onErrorMessage],
          )}
          onPartialSuccess={React.useCallback(
            (...args) => {
              const message =
                onPartialSuccessMessage(...args) ||
                handleEmailSubscribed(args[2]) ||
                takeMessage(node$messages?.onError || props$messages?.onError);
              if (message)
                snackbar.show({
                  message,
                  variant: 'error',
                  className: formName ? `${formName}-error` : undefined,
                });
            },
            [onPartialSuccess],
          )}
          onReject={React.useCallback(
            (...args) => {
              const message =
                onRejectMessage(...args) ||
                takeMessage(
                  node$messages?.onReject || props$messages?.onReject,
                );

              if (message)
                snackbar.show({
                  message,
                  variant: 'error',
                  className: formName ? `${formName}-error` : undefined,
                });
              onReject(...args);
            },
            [onReject, onRejectMessage],
          )}
        />
      );
    };

    WithPartialSuccessResponseSnackbar.defaultProps = {
      onSuccess: undefined,
      onError: undefined,
      onErrorMessage: undefined,
      onReject: undefined,
      onRejectMessage: undefined,
    };

    return WithPartialSuccessResponseSnackbar;
  };
}
