// @flow
import React from 'react';
import Helmet from 'react-helmet';
import castArray from 'lodash/castArray';
import SchemaOrg from './SchemaOrg';

/** The required types should be respected for a good SEO approach and ensure best practices */
export type Props = {
  schemaKey?: ?string,
  locale: string,
  lang: string,
  title: string,
  description: string,
  keywords?: Array<string>,
  url: string,
  siteUrl: string,
  pageType?: ?string, // all pages are "website" by default
  siteName: string,
  image: {
    url: string,
    alt: string,
    width: string,
    height: string,
  },
  datePublished: string,
  dateModified: string,
  alternateName: string,
  author: {
    name: string,
    email: string,
  },
  organization: {
    name: string,
    url: string,
    email: string,
    logo: {
      url: string,
      alt: string,
      width: string,
      height: string,
    },
  },
  social?: {
    twitter?: ?string,
    fbAppID?: ?string,
  },
};

const SEO = ({
  schemaKey,
  locale,
  lang,
  title,
  keywords,
  description,
  url,
  siteUrl,
  pageType,
  siteName,
  image,
  datePublished,
  dateModified,
  alternateName,
  author,
  organization,
  social,
}: Props) => {
  return (
    <>
      <Helmet>
        {/* General Tags */}
        <html lang={lang} />
        <title>{title}</title>
        {keywords && keywords.length ? (
          <meta name="keywords" content={castArray(keywords).join(',')} />
        ) : null}
        <link rel="canonical" href={url} />
        {author && author.name && <meta name="author" content={author.name} />}

        {/* OpenGraph tags */}
        <meta property="og:url" content={url} />
        <meta property="og:type" content={pageType} />
        <meta property="og:locale" content={locale} />
        <meta property="og:site_name" content={siteName} />
        <meta name="title" property="og:title" content={title} />
        <meta
          name="description"
          property="og:description"
          content={description}
        />
        <meta name="image" property="og:image" content={image.url} />
        <meta property="og:image:alt" content={image.alt} />
        <meta property="og:image:width" content={image.width} />
        <meta property="og:image:height" content={image.height} />
        {social?.fbAppID ? (
          <meta property="fb:app_id" content={social?.fbAppID} />
        ) : null}

        {/* Twitter Card tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={image.url} />
        <meta name="twitter:image.alt" content={image.alt} />
        {social?.twitter ? (
          <meta name="twitter:site" content={social?.twitter} />
        ) : null}
        {social?.twitter ? (
          <meta name="twitter:creator" content={social?.twitter} />
        ) : null}
      </Helmet>
      {/* OpenGraph tags for articles only */}
      {pageType === 'article' ? (
        <Helmet>
          <meta property="article:published_time" content={datePublished} />
          <meta property="article:modified_time" content={dateModified} />
          <meta
            property="article:author:profile.first_name"
            content={author.name}
          />
        </Helmet>
      ) : null}
      {/* Schema.org tags */}
      <SchemaOrg
        schemaKey={schemaKey}
        isBlogPost={pageType === 'article'}
        url={url}
        title={title}
        description={description}
        image={image}
        datePublished={datePublished}
        dateModified={dateModified}
        siteUrl={siteUrl}
        siteName={siteName}
        author={author}
        organization={organization}
        alternateName={alternateName}
      />
    </>
  );
};

SEO.defaultProps = {
  schemaKey: undefined,
  keywords: [],
  pageType: 'website', // all pages are "website" by default
  social: undefined,
};

export default SEO;
