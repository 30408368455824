// @flow
import * as React from 'react';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';

import * as variants from '~plugins/material-ui/modules/snackbar/config/variants';

export default ({
  [variants.default]: null,
  [variants.success]: CheckCircleIcon,
  [variants.info]: InfoIcon,
  [variants.error]: ErrorIcon,
  [variants.warning]: WarningIcon,
}: $ObjMap<typeof variants, () => ?React.ComponentType<any>>);
