// @flow
import * as React from 'react';
import withStyles, {
  type StyleRulesCallback,
  type WithStyles,
} from '@plugins/material-ui/hocs/withStyles';
import type { Theme } from '@material-ui/core/styles';
import Button, { type ButtonProps } from '@material-ui/core/Button';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import classnames from 'classnames';

import Link from '~plugins/i18n/components/Link';
import type { PrismicLinkType } from '~schema';

import getMenuType from '../../helpers/getMenuType';
import DrawerItemMenuLinks from './DrawerItemMenuLinks';

export type ClassKey =
  | 'root'
  | 'wrapper'
  | 'text'
  | 'expandedBase'
  | 'expandedBaseDetails'
  | 'expandedBaseItem';

export type Props = {
  ...$Exact<ButtonProps<'button', {}>>,
  ...$Exact<WithStyles<ClassKey>>,
  label: string,
  menuLink: ?PrismicLinkType,
};

export type Styles = StyleRulesCallback<Theme, Props, ClassKey>;

export const styles: Styles = theme => ({
  root: {
    width: '100%',
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  wrapper: {
    textAlign: 'center',
    padding: `${theme.spacing(2)}px ${theme.spacing(2)}px`,
  },
  text: {
    color: theme.palette.common.black,
  },
  expandedBase: {
    margin: '0px !important',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    '&::before': {
      display: 'none !important',
    },
  },
  expandedBaseItem: {
    padding: 0,
    width: 'auto',
  },
  expandedBaseDetails: {
    padding: 0,
  },
});

const DrawerItem = ({
  label,
  menuLink,
  classes,
  ...props
}: Props): React.Node => {
  const menu = getMenuType(menuLink);

  const ExpansionPanelContainer = ({ wrapper, children }) =>
    menu ? wrapper(children) : children;

  return (
    <ExpansionPanelContainer
      wrapper={children => (
        <ExpansionPanel elevation={0} className={classes.expandedBase}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            {children}
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className={classes.expandedBaseDetails}>
            <DrawerItemMenuLinks menuLinks={menu} />
          </ExpansionPanelDetails>
        </ExpansionPanel>
      )}
    >
      <Button
        component={Link}
        aria-label={label}
        disableRipple
        className={classes.root}
        {...props}
      >
        <ListItem
          {...(!menu ? { divider: true } : null)}
          className={classnames(classes.wrapper, {
            [classes.expandedBaseItem]: menu,
          })}
        >
          <ListItemText
            primary={label}
            primaryTypographyProps={{ variant: 'h5', className: classes.text }}
          />
        </ListItem>
      </Button>
    </ExpansionPanelContainer>
  );
};

export default withStyles<*, *, Props>(styles, { name: 'DrawerItem' })(DrawerItem);
