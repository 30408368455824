// @flow
import * as React from 'react';

import type { State } from './types';

export const defaultState = {
  entryTags: [],
};

export default React.createContext<State>(defaultState);
