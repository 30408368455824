// @flow
import type { Props } from '../AppBarLink';

const getButtonVariant = (variant: ?string): $ElementType<Props, 'variant'> =>
  (variant &&
    {
      Contained: 'contained',
      Outlined: 'outlined',
    }[variant]) ||
  undefined;

export default getButtonVariant;
