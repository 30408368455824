// @flow
import * as React from 'react';
import MUICookieConsent from 'material-ui-cookie-consent';

import * as consentTypes from '../config/consentTypes';

type MUICookiesConsentProps = React.ElementConfig<typeof MUICookieConsent>;

type ComponentType = $ElementType<MUICookiesConsentProps, 'componentType'>;

const componentTypes: {
  [string]: ComponentType,
} = {
  [consentTypes.SNACKBAR]: 'Snackbar',
  [consentTypes.DIALOG]: 'Dialog',
};

export default function getCookieConsentComponentType(
  value: string,
): ComponentType | void {
  return componentTypes[value] || undefined;
}
