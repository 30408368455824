/* eslint-disable react/jsx-filename-extension */
// @flow

import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

export default function useRecaptcha() {
  const recaptchaRef = React.createRef();
  const [token, setToken] = React.useState(null);

  const onLoadWithReCAPTCHA = React.useCallback(
    async () =>
      setToken(
        recaptchaRef.current && (await recaptchaRef.current.executeAsync()),
      ),
    [],
  );

  if (!process.env.GATSBY_RECAPTCHA_SITEKEY) return [null, null];

  return [
    <ReCAPTCHA
      ref={recaptchaRef}
      key="recaptcha"
      size="invisible"
      sitekey={process.env.GATSBY_RECAPTCHA_SITEKEY}
      asyncScriptOnLoad={onLoadWithReCAPTCHA}
    />,
    token,
  ];
}
