// @flow

export const FRAGMENT_HREF = 'https://#';

export function isFragmentLink(url: ?string): boolean {
  return !!url && url.startsWith(FRAGMENT_HREF);
}

export function sanitizeFragmentLink(url: ?string): ?string {
  return url && url.replace(FRAGMENT_HREF, '#');
}

export function queryFragmentElement(url: ?string) {
  const selector = sanitizeFragmentLink(url);
  return typeof document === 'undefined' || !selector
    ? null
    : document.querySelector(selector);
}
