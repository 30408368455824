// @flow
import type { Config } from '../src/plugins/prismic-cookies/types';

const { baseCookieKey } = require('./siteMetadata');

/* GatsbyStarterConfig: cookies */
module.exports = ({
  cookieName: `${baseCookieKey}-cookie-consent`,
  componentTypeByPath: {},
}: Config);
