// @flow
import * as React from 'react';
import {
  Context as PrivateContext,
  publicContext as PublicContext,
} from 'gatsby-plugin-transition-link/context/createTransitionContext';

export type TransitionStatus = 'exiting' | 'exited' | 'entering' | 'entered';

export type Config = {|
  location: Location,
  scrollTimeoutMs?: number,
|};

export default function useTransitionLinkScrollIntoAnchor({
  location,
  scrollTimeoutMs = 100,
}: Config) {
  const privateContext = React.useContext(PrivateContext);
  const publicContext = React.useContext(PublicContext);
  const hashRaw: ?string = privateContext?.hash || location.hash || null;
  const hash =
    (hashRaw && hashRaw.replace(/^#/, '').replace(/\/$/, '')) || null;
  const inTransition: boolean = privateContext?.inTransition || false;
  const transitionStatus: TransitionStatus | null =
    publicContext?.transitionStatus || null;

  const transitionStatusRef = React.useRef(null);
  const inTransitionRef = React.useRef(null);

  React.useEffect(() => {
    const isInitialAnchor =
      transitionStatus === ('entered': TransitionStatus) &&
      transitionStatusRef.current === null &&
      inTransition === false &&
      inTransitionRef.current === null;
    const isSamePageAnchor =
      transitionStatus === ('entered': TransitionStatus) &&
      transitionStatus === transitionStatusRef.current &&
      inTransition;
    const isOtherPageAnchor =
      transitionStatus === ('entering': TransitionStatus) &&
      transitionStatusRef.current === ('exited': TransitionStatus) &&
      inTransition &&
      inTransitionRef.current;

    if (isInitialAnchor || isSamePageAnchor || isOtherPageAnchor) {
      const element = hash ? document.querySelector(`#${hash}`) : null;
      if (element) {
        setTimeout(() => {
          element.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
        }, scrollTimeoutMs);
      }
    }

    transitionStatusRef.current = transitionStatus;
    inTransitionRef.current = inTransition;
  }, [transitionStatus, inTransition, hash]);
}
