// @flow
import * as React from 'react';
import { useIntl } from 'react-intl';
import * as yup from 'yup';

import type { PrismicNewsletterForm } from '~schema';

import type { NewsletterFormValues } from './types';
import messages from './messages';

const useNewsletterFormValidationSchema = (node: ?PrismicNewsletterForm) => {
  const intl = useIntl();
  return React.useMemo(
    () =>
      yup.object<NewsletterFormValues>({
        email: yup
          .string()
          .required(
            node?.data?.error_required?.text ||
              intl.formatMessage(messages.errors.required),
          )
          .email(
            node?.data?.error_required?.text ||
              intl.formatMessage(messages.errors.email),
          ),
        inputSector: yup.string().required(),
      }),
    [node],
  );
};

export default useNewsletterFormValidationSchema;
