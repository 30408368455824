// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-templates-blog-blog-entry-page-jsx": () => import("./../../../src/pages-templates/blog/BlogEntryPage.jsx" /* webpackChunkName: "component---src-pages-templates-blog-blog-entry-page-jsx" */),
  "component---src-pages-templates-complex-page-jsx": () => import("./../../../src/pages-templates/ComplexPage.jsx" /* webpackChunkName: "component---src-pages-templates-complex-page-jsx" */),
  "component---src-pages-templates-contact-page-jsx": () => import("./../../../src/pages-templates/ContactPage.jsx" /* webpackChunkName: "component---src-pages-templates-contact-page-jsx" */),
  "component---src-pages-templates-home-page-jsx": () => import("./../../../src/pages-templates/HomePage.jsx" /* webpackChunkName: "component---src-pages-templates-home-page-jsx" */),
  "component---src-pages-templates-know-us-page-jsx": () => import("./../../../src/pages-templates/KnowUsPage.jsx" /* webpackChunkName: "component---src-pages-templates-know-us-page-jsx" */),
  "component---src-pages-templates-news-news-entry-archive-page-jsx": () => import("./../../../src/pages-templates/news/NewsEntryArchivePage.jsx" /* webpackChunkName: "component---src-pages-templates-news-news-entry-archive-page-jsx" */),
  "component---src-pages-templates-news-news-entry-page-jsx": () => import("./../../../src/pages-templates/news/NewsEntryPage.jsx" /* webpackChunkName: "component---src-pages-templates-news-news-entry-page-jsx" */),
  "component---src-pages-templates-partner-page-jsx": () => import("./../../../src/pages-templates/PartnerPage.jsx" /* webpackChunkName: "component---src-pages-templates-partner-page-jsx" */),
  "component---src-pages-templates-press-press-entry-archive-page-jsx": () => import("./../../../src/pages-templates/press/PressEntryArchivePage.jsx" /* webpackChunkName: "component---src-pages-templates-press-press-entry-archive-page-jsx" */),
  "component---src-pages-templates-press-press-entry-page-jsx": () => import("./../../../src/pages-templates/press/PressEntryPage.jsx" /* webpackChunkName: "component---src-pages-templates-press-press-entry-page-jsx" */),
  "component---src-pages-templates-prismic-preview-page-jsx": () => import("./../../../src/pages-templates/PrismicPreviewPage.jsx" /* webpackChunkName: "component---src-pages-templates-prismic-preview-page-jsx" */),
  "component---src-pages-templates-product-page-jsx": () => import("./../../../src/pages-templates/ProductPage.jsx" /* webpackChunkName: "component---src-pages-templates-product-page-jsx" */),
  "component---src-pages-templates-redirect-redirect-page-jsx": () => import("./../../../src/pages-templates/redirect/RedirectPage.jsx" /* webpackChunkName: "component---src-pages-templates-redirect-redirect-page-jsx" */),
  "component---src-pages-templates-resellers-page-jsx": () => import("./../../../src/pages-templates/ResellersPage.jsx" /* webpackChunkName: "component---src-pages-templates-resellers-page-jsx" */),
  "component---src-pages-templates-sector-page-jsx": () => import("./../../../src/pages-templates/SectorPage.jsx" /* webpackChunkName: "component---src-pages-templates-sector-page-jsx" */),
  "component---src-pages-templates-simple-page-jsx": () => import("./../../../src/pages-templates/SimplePage.jsx" /* webpackChunkName: "component---src-pages-templates-simple-page-jsx" */),
  "component---src-pages-templates-solution-page-jsx": () => import("./../../../src/pages-templates/SolutionPage.jsx" /* webpackChunkName: "component---src-pages-templates-solution-page-jsx" */),
  "component---src-pages-templates-use-case-archive-page-jsx": () => import("./../../../src/pages-templates/UseCaseArchivePage.jsx" /* webpackChunkName: "component---src-pages-templates-use-case-archive-page-jsx" */),
  "component---src-pages-templates-use-case-page-jsx": () => import("./../../../src/pages-templates/UseCasePage.jsx" /* webpackChunkName: "component---src-pages-templates-use-case-page-jsx" */),
  "component---src-pages-templates-white-paper-archive-page-jsx": () => import("./../../../src/pages-templates/WhitePaperArchivePage.jsx" /* webpackChunkName: "component---src-pages-templates-white-paper-archive-page-jsx" */)
}

