/* eslint-disable global-require */
/* eslint-disable babel/new-cap */

exports.onClientEntry = async (unused, pluginOptions = {}) => {
  const polyfills = {
    Locale: true,
    getCanonicalLocales: true,
    DisplayNames: false,
    ListFormat: false,
    PluralRules: true,
    NumberFormat: true,
    DateTimeFormat: true,
    RelativeTimeFormat: true,
    ...pluginOptions,
  };

  await Promise.all(
    []
      .concat(
        // @see https://formatjs.io/docs/polyfills/intl-locale
        !!polyfills.Locale ? [import('@formatjs/intl-locale/polyfill')] : [],
      )
      .concat(
        // @see https://formatjs.io/docs/polyfills/intl-getcanonicallocales
        !!polyfills.getCanonicalLocales
          ? [import('@formatjs/intl-getcanonicallocales/polyfill')]
          : [],
      )
      .concat(
        // @see https://formatjs.io/docs/polyfills/intl-displaynames
        !!polyfills.DisplayNames
          ? [import('@formatjs/intl-displaynames/polyfill')]
          : [],
      )
      .concat(
        // @see https://formatjs.io/docs/polyfills/intl-listformat
        !!polyfills.ListFormat
          ? [import('@formatjs/intl-listformat/polyfill')]
          : [],
      )
      .concat(
        // @see https://formatjs.io/docs/polyfills/intl-pluralrules
        !!polyfills.PluralRules
          ? [import('@formatjs/intl-pluralrules/polyfill')]
          : [],
      )
      .concat(
        // @see https://formatjs.io/docs/polyfills/intl-numberformat
        !!polyfills.NumberFormat
          ? [import('@formatjs/intl-numberformat/polyfill')]
          : [],
      )
      .concat(
        // @see https://formatjs.io/docs/polyfills/intl-datetimeformat/
        !!polyfills.DateTimeFormat
          ? [import('@formatjs/intl-datetimeformat/polyfill')]
          : [],
      )
      .concat(
        // @see https://formatjs.io/docs/polyfills/intl-relativetimeformat
        !!polyfills.RelativeTimeFormat
          ? [import('@formatjs/intl-relativetimeformat/polyfill')]
          : [],
      ),
  );
};
