// @flow
import type { PrismicLinkType } from '~schema';
import getMenuType from '../../../helpers/getMenuType';

const defaultMaxLinksPerColumn = 6;

export default function isMaxLinksPerColumnReached({
  menuLink,
  maxLinksPerColumn = defaultMaxLinksPerColumn,
}: {
  menuLink: ?PrismicLinkType,
  maxLinksPerColumn: number,
}) {
  const menu = getMenuType(menuLink);
  return !!(
    menu &&
    menu.data &&
    menu.data.menu_actions &&
    menu.data.menu_actions.length >= maxLinksPerColumn
  );
}
