// @flow
import getPaddingFactor from './helpers/getPaddingFactor';
import getPaddingStyles from './helpers/getPaddingStyles';
import getThemeResPaddingProps from './helpers/getThemeResPaddingProps';
import type { Styles } from './types';

const styles: Styles = theme => ({
  root: {
    width: '100%',
  },
  horizontal: ({ negative }) =>
    negative
      ? {
          marginLeft: theme.spacing(1),
          marginRight: theme.spacing(1),
          ...getPaddingStyles(
            theme,
            ['marginLeft', 'marginRight'],
            -1,
            ({ breakpoint }) => ({
              width: `calc(100% + ${theme.spacing(
                2 *
                  getPaddingFactor(
                    breakpoint,
                    getThemeResPaddingProps(theme)?.breakpointFactors,
                  ),
              )}px)`,
            }),
          ),
        }
      : {
          paddingLeft: theme.spacing(1),
          paddingRight: theme.spacing(1),
          ...getPaddingStyles(theme, ['paddingLeft', 'paddingRight']),
        },
  vertical: ({ negative }) =>
    negative
      ? {
          marginTop: theme.spacing(1),
          marginBottom: theme.spacing(1),
          ...getPaddingStyles(
            theme,
            ['marginTop', 'marginBottom'],
            -1,
            ({ breakpoint }) => ({
              height: `calc(100% + ${theme.spacing(
                2 *
                  getPaddingFactor(
                    breakpoint,
                    getThemeResPaddingProps(theme)?.breakpointFactors,
                  ),
              )}px)`,
            }),
          ),
        }
      : {
          paddingTop: theme.spacing(1),
          paddingBottom: theme.spacing(1),
          ...getPaddingStyles(theme, ['paddingTop', 'paddingBottom']),
        },
});

export default styles;
