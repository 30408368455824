// @flow
import type { Styles } from './types';

const styles: Styles = unusedTheme => ({
  root: {
    position: 'relative',
  },
  disabled: {},
  progressWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  progress: {},
});

export default styles;
