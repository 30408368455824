// @flow
import * as React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  forcedImports: {
    display: 'none !important',
  },
});

const MuiBaseline = () => {
  const classes = useStyles();
  return (
    <>
      <CssBaseline />
      <div className={classes.forcedImports}>
        {/*
          The following components are rendered so their styles are always
          available, event they are not directly imported/used in the app/route.

          We basically do this because how `RichText` works: Prismic's fields html
          are serialized in the server and rendered afterwards as inner html, so the
          components used will not be imported. By importing and rendering them here
          we ensure that their classes will be available.
         */}
        <Typography />
      </div>
    </>
  );
};

export default MuiBaseline;
