// @flow

import type { SiteMetadata$Config } from '../src/plugins/site/types';

const getEnvVar = require('../src/plugins/site/helpers/getEnvVar');
const newDate = require('../src/helpers/newDate');

const siteUrl = getEnvVar('SITE_URL') ?? `https://www.invelon.com/`;

const logo = (() => {
  const logoString = getEnvVar('SITE_METADATA_LOGO');
  const fallback = {
    url: 'https://www.invelon.com/IN-logo-black.png',
    alt: 'brand image',
    width: '488',
    height: '106',
  };
  try {
    return (logoString && JSON.parse(logoString)) || fallback;
  } catch (error) {
    console.error(error);
    return fallback;
  }
})();

const author = {
  name: getEnvVar('AUTHOR_NAME') ?? 'Invelon Technologies',
  email: getEnvVar('AUTHOR_EMAIL') ?? 'info@invelon.com',
};

/* GatsbyStarterConfig: site metadata */
module.exports = ({
  siteName: getEnvVar('SITE_NAME') ?? 'Invelon Technologies',
  siteUrl,
  title: getEnvVar('TITLE') ?? `Invelon Technologies`,
  description:
    getEnvVar('DESCRIPTION') ??
    `Helping ambitious companies to grow through 4.0 technology`,
  author,
  image: logo,
  organization: {
    name: author.name,
    url: siteUrl,
    email: author.email,
    logo,
  },

  datePublished: newDate().toISOString(),
  dateModified: newDate().toISOString(),
  social: {
    twitter: getEnvVar('TWITTER'),
    fbAppID: '',
  },
  baseCookieKey: getEnvVar('BASE_COOKIE_KEY') || 'invelon-cookies',
}: SiteMetadata$Config);
