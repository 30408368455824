// @flow
/* eslint-disable no-use-before-define */
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import noop from 'lodash/noop';

export type Position = 'append' | 'prepend';

export type Props =
  | {
      children: React.Node,
      targetSelector?: ?string,
      target: ?HTMLElement,
      position?: Position,
    }
  | {
      children: React.Node,
      targetSelector: string,
      target?: ?HTMLElement,
      position?: Position,
    };

const Portal = (props: Props) => {
  const { children } = props;

  const target = useTarget(props);

  return target ? ReactDOM.createPortal(children, target) : null;
};

export default Portal;

function useTarget(props) {
  const { position } = props;

  const targetRef = React.useRef();

  React.useLayoutEffect(() => {
    const targetElement = 
      props.target ||
      (props.targetSelector
        ? document.querySelector(props.targetSelector)
        : null);

    switch (position) {
      case 'append':
        targetRef.current = targetElement;
        return noop;
      case 'prepend': {
        const container = document.createElement('div');
        if (targetElement) {
          targetElement.prepend(container);
        }
        targetRef.current = container;
        return () => {
          if (targetElement) {
            targetElement.removeChild(container);
          }
        };
      }
      default:
        const unusedFlowCheck: void = position;
        return noop;
    }
  }, [props.target, props.targetSelector, position]);

  return targetRef.current;
}
