// @flow

export default {
  'contactForm.errors.email': 'Deve essere un indirizzo email valido',
  'contactForm.errors.required': 'Obbligatorio',
  'contactForm.errors.mustAcceptPrivacyPolicy': 'Deve accettare la politica sulla privacy',
  'contactForm.labels.name': 'Nome',
  'contactForm.labels.body': 'Descrizione',
  'contactForm.labels.email': 'Indirizzo email',
  'contactForm.labels.phone': 'Telefono',
  'contactForm.labels.privacyPolicy': 'Politica sulla privacy',
  'contactForm.labels.acceptPrivacyPolicy':
    'Accetto {link, select, undefined {politica sulla privacy} other {{link}}}',
  'contactForm.labels.subject': 'Soggetto',
  'contactForm.labels.submit': 'Invia',
  'contactForm.onError': 'Impossibile inviare la presentazione.',
  'contactForm.onReject': 'Impossibile inviare la presentazione.',
  'contactForm.onSuccess': 'Presentazione inviata!',
  'prismic.preview.nodePageInfo': `
    <p>
      <strong><code>{path}</code></strong> pagina di anteprima
    </p>
    <p>Considerazioni sulla pagina di anteprima:</p>
    <ul>
      {translationsCount, plural, =0 {} other {
      <li>les traductions ne se chargeront pas</li>
      } }
      <li>i dati di visualizzazione perdono quando si cambia pagina</li>
    </ul>
  `,
  'newsletterForm.errors.email': 'Deve essere un indirizzo email valido',
  'newsletterForm.errors.required': 'Obbligatorio',
  'newsletterForm.labels.email': 'Indirizzo email',
  'newsletterForm.labels.submit': 'Invia',
  'reseller.labels.types': 'TIPO DI RIVENDITORE',
  'reseller.labels.resellerLocation': 'Vedi posizione',
  'reseller.labels.resellerLocations': 'Altre posizioni di {reseller}',
  'filter.labels.results': 'risultati',
  'filter.labels.postcode': 'Codice postale',
  'filter.labels.enterPostcode': 'Inserisci un codice postale valido',
};
