// @flow
import * as React from 'react';

import i18n from '../i18n';
import usePageContext from '../../page-context/hooks/usePageContext';

export type LocalizePath = string => string;

export default function usePageTranslations(): LocalizePath {
  const { locale } = usePageContext();
  return React.useCallback(path => i18n.localizePath(locale, path), [locale]);
}
