// @flow
/* eslint-disable import/no-mutable-exports */
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import createPalette from '@material-ui/core/styles/createPalette';
import responsiveFontSizes from '@material-ui/core/styles/responsiveFontSizes';
import mediaQuery from 'css-mediaquery';

import { INITIAL_BREAKPOINT } from './constants';
import type { ThemeOptions } from './types';

const titleFontFamily = ['Rajdhani', 'sans-serif'];
const bodyFontFamily = ['Roboto', 'sans-serif'];

/* GatsbyStarterConfig: material-ui theme */
const palette = createPalette({
  primary: {
    main: process.env.GATSBY_THEME_PALETTE_PRIMARY_MAIN ?? '#B4003C',
    light: process.env.GATSBY_THEME_PALETTE_PRIMARY_LIGHT ?? '#FFE1EB',
    dark: process.env.GATSBY_THEME_PALETTE_PRIMARY_DARK ?? '#890631',
    contrastText: '#fff',
  },
  secondary: {
    main: '#fff',
  },
  text: {
    primary: '#535354',
  },
  background: {
    default: '#FFF',
  },
});

let theme = createMuiTheme(
  ({
    palette,
    // $FlowFixMe --> Reason: font display
    typography: {
      h1: {
        fontFamily: titleFontFamily,
        fontDisplay: 'swap',
        fontSize: '3.75rem',
      },
      h2: {
        fontFamily: bodyFontFamily,
        fontDisplay: 'swap',
        fontSize: '1.5rem',
        lineHeight: '1.5',
      },
      h3: {
        fontFamily: titleFontFamily,
        fontDisplay: 'swap',
        fontSize: '3rem',
      },
      h4: {
        fontFamily: titleFontFamily,
        fontDisplay: 'swap',
        fontSize: '2.125rem',
      },
      h5: {
        fontFamily: titleFontFamily,
        fontDisplay: 'swap',
        fontSize: '1.5rem',
      },
      h6: {
        fontFamily: titleFontFamily,
        fontDisplay: 'swap',
        fontSize: '1.25rem',
      },
      subtitle1: {
        fontFamily: bodyFontFamily,
        fontWeight: 400,
        fontDisplay: 'swap',
      },
      subtitle2: {
        fontFamily: bodyFontFamily,
        fontWeight: 400,
        fontDisplay: 'swap',
      },
      body1: {
        fontFamily: bodyFontFamily,
        fontDisplay: 'swap',
      },
      body2: {
        fontFamily: bodyFontFamily,
        fontDisplay: 'swap',
      },
      caption: {
        fontFamily: bodyFontFamily,
        fontDisplay: 'swap',
      },
      button: {
        fontFamily: titleFontFamily,
        fontDisplay: 'swap',
        fontSize: '1rem',
      },
      overline: {
        fontFamily: titleFontFamily,
        fontDisplay: 'swap',
        fontSize: '0.875rem',
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 1025,
        lg: 1280,
        xl: 1920,
      },
    },
    props: {
      MuiWithWidth: { initialWidth: INITIAL_BREAKPOINT },
      MuiUseMediaQuery: {
        ssrMatchMedia:
          typeof window === 'undefined'
            ? (query: string) => ({
                matches: mediaQuery.match(query, {
                  width: theme.breakpoints.values[INITIAL_BREAKPOINT],
                }),
              })
            : undefined,
      },
      BodySection: {
        paddingFactor: [4, 0],
        paddingAlterFactor: {
          xs: 1.25,
          md: 2,
        },
      },
    },
    overrides: {
      MuiCssBaseline: {
        '@global': {
          '.MuiListText-root': {
            margin: 0,
            position: 'relative',
          },
          '.MuiListText-padding': {
            paddingTop: 8,
            paddingBottom: 8,
          },
          '.MuiListText-ordered': {},
          '.MuiPreformatted-root': {
            maxWidth: '100%',
            overflowX: 'auto',
            fontSize: '1rem',
          },
        },
      },
      BodySection: {
        backgroundColors: {
          grey: '#F6F6F6',
        },
      },
      MuiFormControl: {
        root: {
          '&[hidden]': {
            display: 'none',
          },
        },
      },
      MuiFormLabel: {
        root: {
          color: 'rgba(0, 0, 0, 0.65)',
        },
      },
      MuiOutlinedInput: {
        root: {
          borderRadius: 0,
        },
      },
      MuiButton: {
        root: {
          borderRadius: '0px !important',
          minHeight: 50,
        },
        outlined: {
          padding: '10px 20px',
          border: '1px solid rgba(68, 44, 46, 0.2)',
        },
        contained: {
          padding: '10px 20px',
        },
        text: {
          backgroundColor: 'transparent !important',
        },
      },
      MuiFilledInput: {
        root: {
          borderRadius: '0px !important',
        },
      },
    },
  }: ThemeOptions),
);

/* GatsbyStarterConfig: responsive font sizes */
theme = responsiveFontSizes(theme, {
  breakpoints: undefined,
  disableAlign: undefined,
  factor: undefined,
  variants: undefined,
});

export default theme;
