// @flow
import * as React from 'react';
import SvgIcon, { type SvgIconProps } from '@material-ui/core/SvgIcon';

export type Props = SvgIconProps;

const MailIcon = ({ ...props }: Props): React.Node => {
  return (
    <SvgIcon width="55.8px" height="52.2px" viewBox="0 0 55.8 52.2" {...props}>
      <path d="M0,22.5l21.2,8.4L51.7,0L0,22.5z M25,32.8l22.2,13.7l8.6-45C55.8,1.5,25,32.8,25,32.8z" />
      <path d="M23.2,52.2L31,41l-7.8-4.8V52.2z" />
    </SvgIcon>
  );
};

export default React.memo<Props>(MailIcon);
