// @flow

import * as React from 'react';
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import { ParallaxProvider } from 'react-scroll-parallax';

import theme from '../../config/material-ui/theme';
import FontsHelmet from '../../config/material-ui/fonts/FontsHelmet';
import AppIntlProvider from '~plugins/i18n/components/AppIntlProvider';
import MuiBaseline from '../../config/material-ui/components/MuiBaseline';
import AppHelmet from '~plugins/helmet/components/AppHelmet';
import PreviewProvider from '~plugins/prismic/components/PreviewProvider';
import Snackbar from '~components/Snackbar';
import SnackbarProvider from '~plugins/material-ui/modules/snackbar/components/SnackbarProvider';
import PageContext, { type Page$Context } from '~plugins/page-context';
import BaseLayout from './BaseLayout';

export type Props = {
  children: React.Node,
  pageContext: Page$Context,
  location: Location,
};

const Layout = ({ children, ...props }: Props) => (
  <ParallaxProvider>
    <AppIntlProvider {...props}>
      <ThemeProvider theme={theme}>
        <PreviewProvider>
          {/* eslint-disable-next-line react/destructuring-assignment */}
          <PageContext.Provider value={props.pageContext}>
            <SnackbarProvider Snackbar={Snackbar}>
              <AppHelmet {...props} />
              <FontsHelmet />
              {/* eslint-disable-next-line react/destructuring-assignment */}
              <BaseLayout location={props.location}>{children}</BaseLayout>
            </SnackbarProvider>
          </PageContext.Provider>
          <MuiBaseline />
        </PreviewProvider>
      </ThemeProvider>
    </AppIntlProvider>
  </ParallaxProvider>
);

export default Layout;
