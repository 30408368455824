// @flow
import type { MessageDescriptor } from 'react-intl';

import type { NewsletterFormValues } from './types';

const prefix = 'newsletterForm';

export default {
  labels: {
    ...({
      email: {
        id: `${prefix}.labels.email`,
        defaultMessage: 'E-mail',
        description: 'E-mail field label',
      },
    }: $ObjMap<
      $Diff<NewsletterFormValues, { inputSector: any }>,
      <V>() => MessageDescriptor,
    >),
    submit: ({
      id: `${prefix}.labels.submit`,
      defaultMessage: 'Submit',
      description: 'Submit button label',
    }: MessageDescriptor),
    privacyPolicy: ({
      id: `${prefix}.labels.privacyPolicy`,
      defaultMessage: 'privacy policy',
      description: 'Privacy policy label',
    }: MessageDescriptor),
  },
  errors: {
    email: ({
      id: `${prefix}.errors.email`,
      defaultMessage: 'Must be a valid email address',
      description: 'Error shown for an email field that has an invalid value',
    }: MessageDescriptor),
    required: ({
      id: `${prefix}.errors.required`,
      defaultMessage: 'Required',
      description: 'Error shown for a required field that has no value',
    }: MessageDescriptor),
    mustAcceptPrivacyPolicy: ({
      id: `${prefix}.errors.mustAcceptPrivacyPolicy`,
      defaultMessage: 'Must accept privacy policy',
      description: 'Error shown when privacy policy field is not accepted',
    }: MessageDescriptor),
  },
  emailAlreadySubscribed: {
    id: `${prefix}.emailAlreadySusbscribed`,
    defaultMessage: 'This email address is already subscribed',
    description: 'Message shown whem email address is already subscribed',
  },
  onSuccess: ({
    id: `${prefix}.onSuccess`,
    defaultMessage: "Submission sent! We've sent you a confirmation email",
    description: 'Message shown when a submission has been succesfully sent',
  }: MessageDescriptor),
  onError: ({
    id: `${prefix}.onError`,
    defaultMessage: 'Could not send submission.',
    description: 'Message shown when a submission has not been accepted',
  }: MessageDescriptor),
  onReject: ({
    id: `${prefix}.onReject`,
    defaultMessage: 'Could not send submission.',
    description: 'Message shown when a submission has not been sent',
  }: MessageDescriptor),
};
