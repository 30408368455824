// @flow
import * as React from 'react';
import withStyles, {
  type StyleRulesCallback,
  type WithStyles,
} from '@plugins/material-ui/hocs/withStyles';
import type { Theme } from '@material-ui/core/styles/createMuiTheme';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import classnames from 'classnames';
import map from 'lodash/map';

import TwitterIcon from '~components/icons/TwitterIcon';
import LinkedinIcon from '~components/icons/LinkedinIcon';
import FacebookIcon from '~components/icons/FacebookIcon';
import InstagramIcon from '~components/icons/InstagramIcon';
import MailIcon from '~components/icons/MailIcon';
import FieldLink from '~plugins/prismic/components/FieldLink';
import type { PrismicStructuredTextType, PrismicLinkType } from '~schema';

export type ClassKey = 'root' | 'title' | 'decorator' | 'iconButton' | 'icon';

export type Props = {
  ...$Exact<WithStyles<ClassKey>>,
  className?: string,
  title?: ?string,
  links: ?Array<?{
    [any]: any,
    social_label?: ?PrismicStructuredTextType,
    social_link?: ?PrismicLinkType,
    social_icon?: ?string,
  }>,
};

export type Styles = StyleRulesCallback<Theme, Props, ClassKey>;

export const styles: Styles = theme => ({
  root: ({ title }) => ({
    ...(title ? { display: 'flex', alignItems: 'center' } : {}),
  }),
  title: {},
  decorator: {
    content: JSON.stringify(''),
    width: 20,
    height: 1,
    backgroundColor: theme.palette.common.white,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  iconButton: {},
  icon: {
    width: 16,
    height: 16,
    [theme.breakpoints.down('xs')]: {
      width: 22,
      height: 22,
    },
  },
});

const icons: { [string]: React.ComponentType<any> } = {
  Twitter: TwitterIcon,
  LinkedIn: LinkedinIcon,
  Facebook: FacebookIcon,
  Instagram: InstagramIcon,
  Email: MailIcon,
};

const SocialIcon = ({ icon, ...props }: { [any]: any, icon: string }) => {
  const Component = icons[icon];
  return Component ? <Component {...props} /> : null;
};

const SocialLinks = ({
  className,
  classes,
  title,
  links,
  ...props
}: Props): React.Node => {
  return (
    <div className={classnames(classes.root, className)} {...props}>
      <Typography variant="caption" className={classes.title}>
        {title}
      </Typography>
      {title ? <div className={classes.decorator} /> : null}
      {map(links, (link, index: number) =>
        link &&
        link.social_label &&
        link.social_icon &&
        icons[link.social_icon] ? (
          <IconButton
            key={index}
            component={FieldLink}
            field={link?.social_link}
            aria-label={link?.social_label?.text || link.social_icon}
            className={classes.iconButton}
          >
            <SocialIcon icon={link.social_icon} className={classes.icon} />
          </IconButton>
        ) : null,
      )}
    </div>
  );
};

SocialLinks.defaultProps = {
  className: undefined,
  title: undefined,
};

export default withStyles<*, *, Props>(styles, { name: 'SocialLinks' })(SocialLinks);
