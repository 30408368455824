// @flow
import type { PrismicLinkType } from '~schema';

import { PrismicLinkTypesValues } from '../config';

export default function hasRichTextFieldValue(
  linkField?: ?PrismicLinkType,
) {
  return (
    linkField &&
    linkField.link_type !== PrismicLinkTypesValues.Any
  );
}
