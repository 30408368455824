// @flow

/* GatsbyStarterConfig: prismic content types */
exports['404_page'] = ('404_page': '404_page');
exports.blog_entry = ('blog_entry': 'blog_entry');
exports.news_entry = ('news_entry': 'news_entry');
exports.news_entry_archive = ('news_entry_archive': 'news_entry_archive');
exports.press_entry = ('press_entry': 'press_entry');
exports.press_entry_archive = ('press_entry_archive': 'press_entry_archive');
exports.contact_page = ('contact_page': 'contact_page');
exports.contact_form = ('contact_form': 'contact_form');
exports.cookie_consent = ('cookie_consent': 'cookie_consent');
exports.home_page = ('home_page': 'home_page');
exports.sector_page = ('sector_page': 'sector_page');
exports.product_page = ('product_page': 'product_page');
exports.partner_page = ('partner_page': 'partner_page');
exports.solution_page = ('solution_page': 'solution_page');
exports.know_us_page = ('know_us_page': 'know_us_page');
exports.simple_page = ('simple_page': 'simple_page');
exports.complex_page = ('complex_page': 'complex_page');
exports.use_case = ('use_case': 'use_case');
exports.use_case_archive = ('use_case_archive': 'use_case_archive');
exports.white_paper = ('white_paper': 'white_paper');
exports.white_paper_archive = ('white_paper_archive': 'white_paper_archive');
exports.white_paper_form = ('white_paper_form': 'white_paper_form');
exports.organization = ('organization': 'organization');
exports.author = ('author': 'author');
exports.site_info = ('site_info': 'site_info');
exports.redirect = ('redirect': 'redirect');
exports.menu = ('menu': 'menu');
exports.entry_tag = ('entry_tag': 'entry_tag');
exports.resellers_page = ('resellers_page': 'resellers_page');
exports.reseller = ('reseller': 'reseller');
exports.country = ('country': 'country');
exports.reseller_form = ('reseller_form': 'reseller_form');
