// @flow
import * as React from 'react';
import queryString, { type ObjectParameters } from 'query-string';
import compact from 'lodash/compact';

import Link from '../../../i18n/components/Link';
import useNodePath, {
  type Config as UseNodePathConfig,
} from '../../hooks/useNodePath';

type LinkProps = React.ElementConfig<typeof Link>;

export type Props = {
  ...$Exact<$Diff<LinkProps, { to: any, localized: any }>>,
  ...$Exact<UseNodePathConfig>,
  params?: ?ObjectParameters,
};

export type State = {
  error: ?Error,
};

const NodeLinkInner = ({ node, params, ...props }: Props) => {
  const baseURL = useNodePath({ node }) ?? '#';

  const fullURL = compact([
    baseURL,
    params && queryString.stringify(params, { arrayFormat: 'bracket' }),
  ]).join(baseURL.includes('?') ? '&' : '?');

  return <Link {...props} localized={false} to={fullURL} />;
};
NodeLinkInner.defaultProps = {
  params: undefined,
};

export default NodeLinkInner;
