// @flow
/* eslint-disable no-new-date/no-new-date */
/* eslint-disable no-bitwise */

export type NewDate = {
  (): Date,
  (void | null): Date,
  (timestamp: number): Date,
  (date: Date): Date,
  (dateString: string): Date,
  (
    year: number,
    month: number,
    day?: number,
    hour?: number,
    minute?: number,
    second?: number,
    millisecond?: number,
  ): Date,
};

const newDate: NewDate = (...args) => {
  const [value] = args;

  if (args.length === 1 && typeof value === 'string') {
    const field =
      value.match(
        /^([+-]?\d{4}(?!\d\d\b))(?:-?(?:(0[1-9]|1[0-2])(?:-?([12]\d|0[1-9]|3[01]))?)(?:[T\s](?:(?:([01]\d|2[0-3])(?::?([0-5]\d))?|24:?00)([.,]\d+(?!:))?)?(?::?([0-5]\d)(?:[.,](\d+))?)?([zZ]|([+-](?:[01]\d|2[0-3])):?([0-5]\d)?)?)?)?$/,
      ) || [];

    const result = new Date(
      field[1],
      (field[2] - 1) | 0,
      // $FlowFixMe --> Reason: string incompatible with number
      field[3] || 1,
      field[4] | 0,
      field[5] | 0,
      field[7] | 0,
      field[8] | 0,
    );
    if (field[9]) {
      result.setUTCMinutes(
        result.getUTCMinutes() -
          result.getTimezoneOffset() -
          (field[10] * 60 + +field[11] || 0),
      );
    }

    return result;
  }

  // $FlowFixMe --> Reason: null or undefined incompatible with string
  return new Date(...args);
};

module.exports = newDate;
