// @flow
import * as React from 'react';

import usePageContext from '../../page-context/hooks/usePageContext';
import type { Page$Context } from '../../page-context/types';

export const getCurrentLocale = (pageContext: Page$Context) =>
  pageContext.locale;

export default function useCurrentLocale() {
  return getCurrentLocale(usePageContext());
}
