// @flow
import * as React from 'react';
import SvgIcon, { type SvgIconProps } from '@material-ui/core/SvgIcon';

export type Props = SvgIconProps;

const FacebookIcon = ({ ...props }: Props): React.Node => {
  return (
    <SvgIcon width="64px" height="64px" viewBox="0 0 64 64" {...props}>
      <path d="M7.1 0h49.8C60.8 0 64 3.2 64 7.1v49.8c0 3.9-3.2 7.1-7.1 7.1H7.1C3.2 64 0 60.8 0 56.9V7.1C0 3.2 3.2 0 7.1 0m46.2 7.1h-8.9C37.6 7.1 32 12.7 32 19.6v8.9h-7.1v10.7H32V64h10.7V39.1h10.7V28.4H42.7v-7.1c0-2 1.6-3.6 3.6-3.6h7.1V7.1z" />
    </SvgIcon>
  );
};

export default React.memo<Props>(FacebookIcon);
