/* eslint-disable no-use-before-define */
// @flow
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import queryString from 'query-string';
import withStyles, {
  type StyleRulesCallback,
  type WithStyles,
} from '@plugins/material-ui/hocs/withStyles';
import type { Theme } from '@material-ui/core/styles/createMuiTheme';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQueryTheme';
import MuiAppBar from '@material-ui/core/AppBar';
import MaterialUILayout from 'material-ui-layout';
import classnames from 'classnames';
import { GCLID_STORAGE_NAME } from '../constants';

import AppBar from '~components/AppBar';
import AppBarContextProvider from '~components/AppBar/AppBarContextProvider';
import useIsAppBarScrolledStyled from '~components/AppBar/hooks/useIsAppBarScrolledStyled';
import Footer from '~components/Footer';
import Drawer from '~components/Drawer';
import FiltersProvider from '~components/FiltersProvider';
import CookieConsent from '~plugins/prismic-cookies/components/CookieConsent';
import usePageContext from '~plugins/page-context/hooks/usePageContext';
import Portal from '~plugins/react/components/Portal';
import useTransitionLinkScrollIntoAnchor from '~hooks/useTransitionLinkScrollIntoAnchor';

export type ClassKey =
  | 'layout'
  | 'layoutMain'
  | 'layoutAppBar'
  | 'layoutDrawer'
  | 'layoutFooter'
  | 'appBar'
  | 'appBarScrolled'
  | 'appBarContent'
  | 'mainOnTop'
  | 'drawer'
  | 'ssr';

export type Props = {
  ...$Exact<WithStyles<ClassKey>>,
  children: React.Node,
  location: Location,
};

export type Styles = StyleRulesCallback<Theme, Props, ClassKey>;

const styles: Styles = theme => ({
  layout: {},
  layoutMain: {
    '$layout$mainOnTop &': {
      marginTop: 0,
    },
  },
  layoutAppBar: {
    '$layout:not($ssr) &': {
      display: 'none',
    },
  },
  layoutDrawer: {},
  layoutFooter: {
    padding: 0,
  },
  appBar: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    transition: theme.transitions.create(['background-color', 'color'], {
      duration: theme.transitions.duration.complex,
    }),
    '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
      /* IE10+ CSS styles go here */
      height: 80,
    },
  },
  appBarScrolled: {
    backgroundColor: theme.palette.common.white,
    boxShadow:
      '0px 2px 4px -1px rgba(0,0,0,0.1), 0px 4px 5px 0px rgba(0,0,0,0.05), 0px 1px 10px 0px rgba(0,0,0,0.1)',
    transition: theme.transitions.create(['background-color', 'color'], {
      duration: theme.transitions.duration.complex,
    }),
  },
  appBarContent: {},
  drawer: {},
  mainOnTop: {},
  ssr: {},
});

const BaseLayout = ({ location, classes, children }: Props) => {
  useTransitionLinkScrollIntoAnchor({ location });
  const { appBarOnTop, originalPath } = usePageContext();
  const is404 = /^\/(404|[a-z]{2}\/404)/.test(originalPath);
  const theme = useTheme<Theme>();
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));

  const isSSR = typeof document === 'undefined';
  const appBar = useAppBar({ classes });

  React.useEffect(() => {
    // Check for search param `gclid` to store its contents in session storage
    if (
      typeof window !== 'undefined' &&
      typeof window.location !== 'undefined' &&
      typeof window.sessionStorage !== 'undefined'
    ) {
      const parsedQuery = queryString.parse(window.location.search);
      const hasGCLID = Object.hasOwn(parsedQuery, 'gclid');
      if (!!hasGCLID) {
        window.sessionStorage.setItem(GCLID_STORAGE_NAME, parsedQuery.gclid);
        // eslint-disable-next-line no-console
        console.info(
          `Stored GCLID in Session Storage under "${GCLID_STORAGE_NAME}".`,
        );
      }
    }
  }, []);

  return (
    <AppBarContextProvider>
      <MaterialUILayout
        mainGrow={false}
        stickyFooter
        appBarPosition="fixed"
        appBarContent={appBar?.placeholder}
        footerContent={<Footer />}
        rightDrawerContent={<Drawer position="right" />}
        classes={React.useMemo(
          () => ({
            layout: classnames(classes.layout, {
              [classes.mainOnTop]: appBarOnTop || is404,
              [classes.ssr]: !!isSSR,
            }),
            main: classes.layoutMain,
            appBar: classnames(classes.layoutAppBar, classes.appBar),
            drawerPaper: classes.layoutDrawer,
            footer: classes.layoutFooter,
          }),
          [classes, appBarOnTop, isSSR],
        )}
      >
        <FiltersProvider mode="search" location={location}>
          {children}
        </FiltersProvider>
        {appBar?.portal}
      </MaterialUILayout>
      <Portal
        target={typeof document === 'undefined' ? null : document.body}
        position="prepend"
      >
        <CookieConsent
          showReadMore
          {...(isSmUp
            ? { snackbarAnchor: { horizontal: 'right', vertical: 'bottom' } }
            : { snackbarAnchor: { horizontal: 'center', vertical: 'top' } })}
        />
      </Portal>
    </AppBarContextProvider>
  );
};

export default withStyles<*, *, Props>(styles, { name: 'BaseLayout' })(
  BaseLayout,
);

function useAppBar({ classes }) {
  const { appBarHidden } = usePageContext();
  const isAppBarScrolled = useIsAppBarScrolledStyled();
  return appBarHidden ? null : {
    isAppBarScrolled,
    placeholder: <AppBar className={classes.appBar} />,
    portal: (
      <Portal
        target={typeof document === 'undefined' ? null : document.body}
        position="prepend"
      >
        <MuiAppBar
          position="fixed"
          className={classnames(classes.appBar, {
            [classes.appBarScrolled]: isAppBarScrolled,
          })}
        >
          <AppBar
            className={classes.appBar}
            {...(isAppBarScrolled ? { scrolled: true } : undefined)}
          />
        </MuiAppBar>
      </Portal>
    ),
  };
}
