// @flow
/* eslint-disable no-use-before-define */
/*:: import type { Locale } from '../../src/plugins/i18n/types'; */

const { i18n } = require('../../src/plugins/i18n');

/* GatsbyStarterConfig: locales ids
Here you can define the locales ids for the app.
!IMPORTANT: make sure that keys and values are the same!
*/
const LocaleIds = Object.freeze({
  es: ('es': 'es'),
  en: ('en': 'en'),
  fr: ('fr': 'fr'),
  ca: ('ca': 'ca'),
  it: ('it': 'it'),
});

/* GatsbyStarterConfig: locales default ids
Define the locales that will be used by default in the app if no locales
are defined through the `GATSBY_I18N_LOCALES` env var.
*/
const localesDefaultIds /*: Array<$Values<typeof LocaleIds>> */ = [
  LocaleIds.es,
  LocaleIds.en,
  LocaleIds.fr,
  LocaleIds.ca,
];

/* GatsbyStarterConfig: locales info
Here you can define the locales for the app.
*/
const localesById /*: $ObjMap<
  typeof LocaleIds,
  <V>() => $Diff<Locale, { id: any }>,
> */ = {
  [LocaleIds.es]: {
    pathPrefix: 'es',
    name: 'Spanish',
    nativeName: 'Español',
    prismicLocale: 'es-es',
    tagIETF: 'es_ES',
  },
  [LocaleIds.en]: {
    pathPrefix: 'en',
    name: 'English',
    nativeName: 'English',
    prismicLocale: 'en-gb',
    tagIETF: 'en_GB',
  },
  [LocaleIds.fr]: {
    pathPrefix: 'fr',
    name: 'French',
    nativeName: 'Français',
    prismicLocale: 'fr-fr',
    tagIETF: 'fr_FR',
  },
  [LocaleIds.ca]: {
    pathPrefix: 'ca',
    name: 'Catalan',
    nativeName: 'Catalàn',
    prismicLocale: 'ca',
    tagIETF: 'ca',
  },
  [LocaleIds.it]: {
    pathPrefix: 'it',
    name: 'Italian',
    nativeName: 'Italiano',
    prismicLocale: 'it-it',
    tagIETF: 'it',
  },
};

/* GatsbyStarterConfig: locales
Define the locales above
*/
i18n.addLocales(getLocales());

/* GatsbyStarterConfig: default locale
Here you can specify the default locale for the app. You can
also omit this configuration, and the first locale in the locales
list will be taken as the default.
*/
// i18n.setDefaultLocale('en');

function getLocalesIds() /*: Array<$Values<typeof LocaleIds>> */ {
  const raw = process.env.GATSBY_I18N_LOCALES;
  let parsed;
  try {
    parsed = JSON.parse(raw ?? '[]');
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    throw i18nLocalesError();
  }
  if (!Array.isArray(parsed)) {
    throw i18nLocalesError();
  }
  if (parsed.length === 0) {
    // support original locales config
    return localesDefaultIds;
  }
  if (
    !parsed.every(locale => typeof locale === 'string' && locale in LocaleIds)
  ) {
    throw i18nLocalesError();
  }
  return parsed;

  function i18nLocalesError() {
    return new Error(
      `Invalid GATSBY_I18N_LOCALES: ${(typeof raw === 'string' && `'${raw}'`) ||
        'undefined'}. Must be a JSONed array of strings. Example of value with all locales: ${JSON.stringify(
        Object.values(LocaleIds),
      )}`,
    );
  }
}

function getLocales() /*: Array<Locale> */ {
  return getLocalesIds().map(id => ({ ...localesById[id], id }));
}
