// @flow
import type { Styles } from './types';

const styles: Styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    overflow: 'scroll',
  },
  menuLinks: {
    flexDirection: 'column',
    flex: '1 1 auto',
  },
  socialLinks: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    padding: `${theme.spacing(2)}px ${theme.spacing(0)}px`,
  },
  socialLink: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    margin: theme.spacing(1),
    padding: theme.spacing(1.5),
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  languagePicker: {
    margin: 'auto',
  },
});

export default styles;
