// @flow
import * as React from 'react';
import type { NewsletterFormValues } from '../types';
import cleanseZohoData from '../../../functions/helpers/cleanseZohoData';

export default function onSubmitNewsletterMapValues(
  originalValues: NewsletterFormValues,
) {
  return {
    createLead: {
      Last_Name: originalValues.email.split('@')[0],
      Email: originalValues.email,
      Industry: cleanseZohoData(originalValues.inputSector) || undefined,
    },
    subscribe: {
      contactData: { 'Contact Email': originalValues.email },
    },
  };
}
