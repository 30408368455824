// @flow

export default {
  'contactForm.errors.email':
    'Ha de ser una adreça de correu electrònic vàlida',
  'contactForm.errors.required': 'Obligatori',
  'contactForm.errors.mustAcceptPrivacyPolicy':
    'És obligatori acceptar la política de privacitat',
  'contactForm.labels.body': 'Descripció',
  'contactForm.labels.email': 'Correu electrònic',
  'contactForm.labels.subject': 'Assumpte',
  'contactForm.labels.submit': 'Enviar',
  'contactForm.labels.privacyPolicy': 'política de privacitat',
  'contactForm.labels.acceptPrivacyPolicy':
    'Accepto la {link, select, undefined {política de privacitat} other {{link}}}',
  'contactForm.onError': "No s'ha pogut enviar el formulari.",
  'contactForm.onReject': "No s'ha pogut enviar el formulari.",
  'contactForm.onSuccess': 'Formulari enviat!',
  'prismic.preview.nodePageInfo': `
    <p>
      Pàgina de previsualització de <strong><code>{path}</code></strong>
    </p>
    <p>Consideracions de les pàginas de previsualització:</p>
    <ul>
      {translationsCount, plural, =0 {} other {
      <li>les traduccions no se carregaran</li>
      } }
      <li>les dades de la previsualización es perdran al canviar de pàgina</li>
    </ul>
  `,
};
